.customHeader {
  height: 70px;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    0 2px 5px 0 rgba(0, 0, 0, 0.05);

  background-color: #ffffff;

  color: #222222;
}

.logo-container img {
  width: 140px;
}

.logo-container.ppg img {
  width: auto;
  height: 40px;
}

.mobileMenu {
  position: absolute;
  top: 71px;
  z-index: 99999;
}
.show-minicart {
  position: relative;
  z-index: 3;
}
.locale-container {
  position: absolute;
  right: 10rem;
}
