.order-details-wrapper {
  width: 100%;
}

.order-inner-wrapper {
  /* background-color: #eff3f6; */
}

.order-top-content {
  background-color: white;
}

.order-top-row {
  padding: 15px 0;
}

.order-top-row .page-title {
  text-transform: uppercase;
  line-height: 45px;
  font-weight: bold;
}

.order-data {
  background-color: #ffffff;
  /* padding: 25px 0; */
  padding: 5% !important;
}

.order-container {
  padding: 0 !important;
}

.table-body td:last-child {
  text-align: left;
}

.MuiPaper-root .MuiCardHeader-root {
  background-color: #273142;
  color: white;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: bold;
}

.MuiCardHeader-root .MuiIconButton-root {
  color: white;
}

.order-summary td {
  border-bottom: none;
  padding: 2px 0;
}

.order-summary td:last-child {
  text-align: right;
}

.order-summary tr:last-child {
  /* border-top: 1px solid rgba(0, 0, 0, 0.12); */
}

.billing-wrapper .MuiCard-root {
  width: 100%;
  float: left;
  margin-right: 25px;
  margin-bottom: 15px;
}

.billing-shipping-container {
  padding: 15px 0;
}

.billing-wrapper .billing-title,
.order-summary-wrapper .summary-title {
  padding: 10px 0;
}

.order-summary-content td:first-child {
  font-weight: bold;
}

.upperCase {
  text-transform: uppercase;
}

.customer-info {
  margin: 10px 0;
}

.color-red {
  color: red;
}

.price {
  color: #237e9a !important;
  text-align: center;
  margin-top: 30px;
  /* text-align: left; */
}
.priceOrder {
  color: #237e9a !important;
  text-align: left;
}
@media (max-width: 768px) {
  .order-data {
    font-size: 80%;
  }

  .billing-wrapper .MuiCard-root {
    width: 100%;
  }
}

.search-header {
  position: relative;
  padding-bottom: 5px;
  top: -10px;
  margin-bottom: 4em;
  width: 100%;
}

.search-header h2 {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  line-height: 2.5;
  letter-spacing: 0.76px;
  color: #273142;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.search-field {
  position: absolute;
  display: inline-block;
  text-align: right;
  right: 0;
}
.MuiExpansionPanelDetails-root,
.order-details-panel {
  background: #ffffff !important;
}
.alignCenter {
  margin: auto;
  height: 200px;
}

/* ADDED from old orders */
.orders-inner-wrapper {
  /* background-color: #eff3f6; */
}

.orders-top-content {
  /* background-color: #fafbfc; */
}

.orders-wrapper {
  margin-bottom: 20px;
}

.ordersTopRow {
  padding: 15px 0;
}

.page-title {
  text-transform: uppercase;
  line-height: 45px;
  font-weight: bold;
}

.orders-data {
  background-color: #ffffff;
  padding: 0 15px;
}

.orders-container {
  padding: 0px;
}

.pagination-row {
  padding: 0 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
}

.view-details-btn {
  border: 1px solid rgba(151, 151, 151, 0.32) !important;
  border-radius: 4px !important;
  background-color: #273142 !important;
  color: #ffffff !important;
}

.table-head th {
  color: black;
  font-weight: bold;
}

.table-head .MuiTableCell-root {
  border-bottom: none;
}

.table-body td:first-child {
  text-transform: uppercase;
}

.table-body td:last-child {
  text-align: right;
}

.table-body .MuiTableCell-root {
  border-bottom: none;
}

.divider {
  margin: 4px;
}

.search-input {
  padding: 0 15px;
}

.table {
  display: table;
  width: 100%;
}

.table-head {
  display: table-header-group;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
}

.table-body {
  display: table-row-group;
}

.order-details-panel {
  border-top: 1px solid #fafbfc;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 0 10px #000;
}

.order-rows td {
  border-bottom: none;
}

.text-right {
  text-align: right;
}

.MuiExpansionPanelDetails-root {
  padding: 0px !important;
}
.orderSearch {
  display: flex !important;
}
.orderSearchPage {
  display: flex !important;
}
.selectOrder {
  /* width: 100%; */
  height: 40px;
  display: flex;
  padding: 0px 5px;
  align-items: center;
}
.selectDrop {
  /* width: 364px; */
  height: 40px;
  background: #fcfcfc;
  color: #a8a8a8 !important;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border: none;
  padding: 0px 5px;
}
.selectDropInvoice {
  background: #fcfcfc;
  color: #a8a8a8 !important;
  border: none;
  padding: 0px 5px;
}
.labelCss {
  color: #5b5e5f;
  padding-right: 5px;
  /* width: 100px; */
}

/* select {
  background: #fcfcfc;
  border: 1px solid #0066ff;
}
select option {
  background-color: transparent !important;
  border: 1px solid #e4e4e4 !important;
  color: #000 !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
} */
.noOrderDisplay {
  height: 100px;
  padding: 20px;
}
@media (max-width: 768px) {
  .orders-data {
    padding: 0;
  }

  .MuiExpansionPanelSummary-root {
    padding: 0px !important;
  }

  .view-details-btn-mob {
    display: flex;
    flex-direction: column-reverse;
  }

  .view-details-btn-mob .view-details-btn {
    font-size: 90%;
  }

  .mobile-grid {
    font-size: 75%;
  }

  .mobile-grid .headers {
    font-weight: bold;
  }

  .pagination-row {
    justify-content: center;
  }
}

.cell-width {
  width: 20%;
  /* padding: 0 !important; */
  /* padding: 12px 0px 12px 12px; */
  padding: 16px !important;
}

.zero-padding {
  padding: 0 !important;
}

.table-head {
  padding: 12px 0px 12px 12px;
}
.priceItem {
  color: #237e9a !important;
  text-align: left !important;
}

.order-rows td,
.order-rows th {
  padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
/* .orderHeadRow{  
  padding: 0;  
} */
.paddingLeft {
  padding-left: 5px;
}

.no-order-text {
  padding: 30px 0px 55px 0px;
}
@media (max-width: 991px) {
  .no-order-text {
    padding: 30px 20px 55px;
  }
}
