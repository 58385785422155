.product-listing {
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

.no-data-found {
  font-size: 18px;
  font-style: italic;
  margin-top: 10px;
  color: #fd5531;
}
