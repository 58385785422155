.displayProp {
  display: flex;
  justify-content: space-between;
}

.image {
  width: 125px;
  height: 110px;
}

.rectangle {
  height: 125px;
  background: #f9fafb;
  border: 1px solid #dfe3e9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 7px;
  margin: 10px 0;
}

.marginDiv {
  margin-left: 60px;
  padding-right: 60px;
}

.items {
  margin-top: 12px;
  color: #237e9a;
  text-align: left;
}

.quickOrderPrice {
  margin-top: 30px;
  text-align: center;
}

.bottomBorder {
  width: 95%;
  border-bottom: 1px solid #ebedf8;
  padding-bottom: 15px;
}

#removeMargin {
  margin: 0;
}

#deleteDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dustBin:hover {
  cursor: pointer;
}

.noStock {
  color: #a01c1c;
  margin-top: 30px;
  /* margin-right: 38px; */
  font-size: medium;
}
.alertMsg {
  color: #a01c1c;
  margin-top: 5px;
  font-size: medium;
}

.nameSize {
  text-align: start;
}

.priceAlign {
  text-align: center;
}

.quickOrderStepper {
  /* display: flex;
  justify-content: center; */
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .image {
    width: 70px;
    height: 50px;
  }

  .marginDiv {
    margin-left: 6px;
    padding-right: 0px;
  }

  .rectangle {
    height: 194px;
  }
  /* .price {
    margin-top: 52px;
  } */

  .noStock {
    margin-top: 0;
    /* margin-right: 47px; */
    font-size: small;
  }
  #deleteDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  /* .price {
    margin-top: 50px;
  } */

  .marginDiv {
    margin-left: 68px;
    padding-right: 0px;
  }

  .rectangle {
    height: 115px;
  }

  .noStock {
    margin-right: 0;
    font-size: small;
  }
  #deleteDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .displayProp {
    margin-left: 10px;
  }
}
