.container-print .table-cell {
  border: none;
  padding: 4px 8px 4px 8px;
}
.container-print {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 50px 0 0 0px;
}
.inner-container-print {
  border: 1px solid rgb(224, 224, 224);
 padding: 4px 8px 4px 8px;
}
.outer-container-print {
  padding: 15px;
}
.marginBottom {
  margin-bottom: 15px;
}
.logoPadding {
  padding: 10px 0px 10px 0px;
}
.borderBottom {
  border-bottom: 1px solid;
}

.marginFromTop {
  top: 10px;
}

.dividerClass {
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: #0000001f;
}
