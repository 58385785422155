.MuiTabs-flexContainer {
  background-color: #fafbfc;
}

.MuiTab-wrapper {
  font-weight: bold;
  text-transform: capitalize;
  /* border: 2px solid green; */
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  /* color: #939696; */
  font-weight: bold;
  color: #3f4243;
}

#contact-text {
  width: 80%;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #5b5e5f;
  padding-bottom: 20px;
}

#contact-text-mobile {
  width: 80%;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #5b5e5f;
}

/* .MuiButtonBase-root {
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #3f4243;
} */
table,
th,
td {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #5b5e5f !important;
}

.MuiTableCell-alignRight {
  text-align: left !important;
  flex-direction: initial;
}

.inner-wrap {
  padding-left: 33px;
  background-color: #fafbfc;
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  /* padding-top: 33px; */
}

.MuiTableSortLabel-root {
  flex-direction: initial;
}

.makeStyles-tableWrapper-101 {
  width: 100%;
}

.MuiBox-root > div {
  display: block !important;
}

.MuiTableCell-root
  MTableHeader-header-298
  MuiTableCell-head
  MuiTableCell-paddingCheckbox {
  display: none;
}

/* New changes */
#account-management-text {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 0.86px;
  color: #3f4143;
  padding-left: 33px;
  text-transform: uppercase;
}

.account-management {
  width: 100%;
  background-color: #eff3f6;
  height: 63px;
  line-height: 63px;
}

/* .tab-padding{
    padding: 3em;   
} */
.row-dimension {
  padding: 30px;
}

.MuiAppBar-colorPrimary {
  background-color: none;
}

.address-container {
  display: flex;
  justify-content: space-between;
  width: 98%;
  border-bottom: solid 1px #dfe3e9;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  width: 98%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #dfe3e9;
}

.cancel-contact-mobile {
  width: 98%;
  border-top: solid 1px #dfe3e9;
}

.search-container {
  padding-bottom: 20px;
}

.add-address-container {
  width: 98%;
  border: solid 1px #dfe3e9;
  border-radius: 5px;
  margin-top: 20px;
}

.addressButtons {
  float: right;
  padding: 20px;
  padding-right: 35px;
  cursor: pointer;
}

.addressDeleteButton {
  float: right;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

#text-input {
  width: 100%;
  height: 40px;
  border: solid 1px #dfe3e9;
  border-radius: 5px;
  padding: 5px;
}

#address {
  padding-top: 40px;
  padding-bottom: 40px;
  /* width: 100%; */
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #5b5e5f;
  /* border-bottom: solid 1px #dfe3e9;
    width: 98%; */
}

#addNewAddress {
  padding-top: 40px;
  padding-bottom: 40px;
  /* width: 100%; */
  height: 50px;
  line-height: 50px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #5b5e5f;
  display: flex;
  cursor: pointer;
  /* border-bottom: solid 1px #dfe3e9;
    width: 98%; */
}

#addContact {
  letter-spacing: normal;
  color: #5b5e5f;
  cursor: pointer;
}

.address-wrapper {
  margin-top: 40px;
  width: 100%;
  padding-left: 15px;
  /* margin-left: 15px; */
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding-bottom: 20px;
}

.MuiGrid-root.display-desktop {
  padding-left: 0px;
}

.address-head {
  width: 97%;
  padding: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  background-color: #e41e26;
  margin-top: 20px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff;
  display: flex;
}

.head-text {
  margin-left: 10px;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #3f4243;
  /* padding: 14px 0px 14px 0px; */
}

.address-card {
  width: 97%;
  height: 20em;
  border-radius: 4px;
  border: solid 1px #dfe3e9;
  padding-left: 20px;
  padding-top: 20px;
  background-color: #ffffff;
  padding-bottom: 10px;
}

.address-one {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.88;
  letter-spacing: normal;
  color: #5b5e5f;
}

.address-card > p {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  /* line-height: 1.5; */
  letter-spacing: normal;
  color: #5b5e5f;
}
.head-address {
  height: 21px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff;
  padding-left: 5px;
  width: 100%;
}

#company-information {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #5b5e5f;
  border-bottom: solid 1px #dfe3e9;
}

.company-wrapper {
  width: 100%;
  padding-left: 15px;
  /* margin-left: 15px; */
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding-bottom: 20px;
}

.circle-account {
  margin-top: 10px;
  width: 45px;
  height: 45px;
  border-radius: 50%;

  color: #fff;
  line-height: 45px;
  text-align: center;
  background-color: #3f4143;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

.company-card {
  background-color: #ffffff;
  padding-left: 3%;
  padding-top: 20px;
  padding-bottom: 10px;
  width: 100%;
}

#company-name {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #5b5e5f;
}

.company-card > p {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5b5e5f;
}

.company {
  padding-top: 20px;
}

/* .head-text{
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #3f4243;
  } */
.inner-wrap > table > thead > tr {
  font-size: 10px;
  font-weight: bold !important;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #3f4243;
}

tr#contactForm > td {
  border-bottom: rgb(236, 234, 233);
  padding-left: 0px;
}

#contactForm .select-input {
  margin-top: 20px;
}

tr#buttonSave > td {
  /* padding: 30px; */
  margin: auto;
}
tr#tableCenterData > td {
  margin: auto;
}
tr#buttonSave
  .MuiCircularProgress-root.MuiCircularProgress-colorSecondary.MuiCircularProgress-indeterminate {
  display: inline-block;
  padding: 5px;
}

.tab-half {
  background-color: #fafbfc;
}

.display-desktop {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}

.address-root {
  flex-grow: 1;
}

.display-address {
  padding-top: 15px;
}
.search {
  border-radius: 4px;
  margin-top: 25px;
  border: solid 1px #dfe3e9;
  height: 41px;
  display: flex;
}
.MuiTableCell-alignRight {
  text-transform: capitalize;
}
.text-bold {
  font-size: 10px !important;
  font-weight: bold !important;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6 !important;
  letter-spacing: normal !important;
  color: #3f4243 !important;
  padding: 14px 0px 14px 0px;
}
#search {
  width: 40%;
  margin-top: 25px;
  display: flex;
}
/* .MuiTableCell-body{
    font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5b5e5f;

} */
.set-padding {
  padding: 14px 8px 14px 8px !important;
}

.add-address-container
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
  font-size: medium;
  font-weight: bold;
  color: #5b5e5f;
}

.add-new-contact-container
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
  font-size: medium;
  font-weight: bold;
  color: #5b5e5f;
}
.addressSelect
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiInputLabel-shrink {
  font-size: medium;
  font-weight: bold;
  color: #5b5e5f;
}
.add-address-container span.MuiIconButton-label {
  font: icon;
}

.add-address-container span.MuiButton-label {
  text-transform: none;
  font-weight: bold;
}

span.filter-option-label {
  font-size: 13px;
  font-weight: bold;
  color: #5b5e5f;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.addressSelect .MuiFormControl-root {
  width: 100%;
}

.MuiGrid-grid-xs-12 .addressSelect {
  margin-left: 8px;
  width: 100%;
}

.account-button {
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.32);
  background-color: rgba(216, 216, 216, 0);
  /* width: 10%; */
  margin-right: 20px;
  margin: 8px;
}
.required label:after {
  color: #e32;
  content: ' *';
  display: inline;
  font-size: 20px;
}

.MuiBox-root-251,
#accounts .MuiBox-root {
  padding: 0 !important;
}

.tab-padding .display-desktop {
  padding-left: 0px;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4.MuiGrid-grid-md-4.MuiGrid-grid-lg-4 {
  margin-right: 10px;
}
.tooltipFont {
  font-size: 12px;
  align-self: center;
}
.editIcon {
  cursor: pointer;
  color: black;
}
.toopTip {
  align-self: center;
  margin-top: 20px;
  margin-left: 20px;
}
.registrationForm {
  display: flex;
  flex-wrap: wrap;
}
.mobPadding {
  padding-left: 25px;
}
.buttonClass {
  margin-left: 10;
  margin-bottom: 10;
  width: 180;
  height: 41;
}

#accounts
  .MuiPaper-root.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-positionStatic.tab-padding.MuiAppBar-colorPrimary {
  z-index: 0;
}
.marginL {
  text-transform: capitalize !important;
  background-color: transparent;
  border: none !important;
  font-size: 0.875rem !important;
}
.marginL:hover {
  text-decoration: none !important;
  background-color: rgba(0, 0, 0, 0.08);
}
.buttonContainer {
  margin-left: 5px !important;
  padding-bottom: 15px;
  padding-top: 5px;
  display: flex;
}
.buttonContainer :focus {
  outline: none;
}
