.search {
  /* height: 38px; */
  width: 100%;
  border-radius: 4px;
  border: solid 1px #dfe3e9;
}

button.search-icon {
  height: 39px;
  width: 41px;
  padding: 0px;
}

.search-input {
  border: none;
  width: 100%;
  height: 39px;
  padding: 0px;
}
