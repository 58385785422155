.confirmationPageLayout {
  margin-left: 10%;
  margin-right: 10%;
}
.successMsg {
  padding: 2em;
  text-align: center;
  margin-bottom: 3em;
}
.footer-icons {
  color: #e41e26;
  padding-top: 20px;
  display: flex;
}
.footer-icons > button {
  color: #e41e26;
}
.footer-icons > a {
  padding-right: 20px;
  padding-left: 20px;
  color: #e41e26;
}
.footer-icons > a > p {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f4243;
  text-decoration: underline;
}
.bottom-links {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.bottom-links > a {
  padding-right: 40px;
  padding-left: 40px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #134c5d;
  text-decoration: underline;
}
.order-footer-data {
  padding-top: 30px;
}
.priceConfirmation {
  text-align: left !important;
}
.loaderMargin {
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 10%;
  margin-right: 10%;
}
