.emailText,
.resetEmailText {
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: #5b5e5f;
  padding: 20px 0px 18px 0px;
  margin-bottom: 0;
}

.emailContainer {
  display: inline-block;
}
.submitButton {
  width: 25%;
  display: block;
  height: 45px;
  font-size: 100%;
}
.emailLabel,
.emailInput {
  font-family: inherit;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.1;
  letter-spacing: normal;
  color: #3f4143;
}
.emailInput {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  color: rgb(0, 0, 0) !important;
  margin-top: 3px;
  margin-bottom: 40px;
}
.loginLink {
  padding-top: 50px;
}
.loginLink a {
  color: #5b5e5f;
  text-decoration: none;
  font-family: inherit;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.backToLogin {
  width: 46px;
  vertical-align: middle;
  height: 46px;
}
.logoContainer {
  margin-top: 50px;
}
.resendEmailContainer {
  padding-top: 19px !important;
}
.resendEmailLink {
  font-family: inherit !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  color: #237e9a !important;
}
.emailHeader {
  padding-top: 51%;
}
.linkLeftMargin {
  margin-left: 46px !important;
}
@media (max-width: 767px) {
  .loginLink > a:hover {
    text-decoration: none !important;
  }
  .emailHeader {
    padding-top: 100px !important;
  }
  .resendEmailContainer {
    padding-top: 22px !important;
    padding-bottom: 80px !important;
  }
  .resendEmailLink {
    text-decoration: none;
  }
  .text-style {
    padding-top: 66px;
  }
  .logoContainer {
    margin-top: 58px;
  }
  .set-border-bottom {
    padding-bottom: 32px;
  }
  .emailText,
  .resetEmailText {
    width: 100%;
    padding: 20px 30px 12px 30px;
    font-size: 16px;
    text-align: center;
    margin: auto;
  }
  .resetEmailText {
    width: 256px;
    padding: 21px 0px 12px 0px;
  }
  .emailContainer {
    text-align: left;
    width: 100%;
    margin: auto;
  }
  .emailInput {
    width: 100%;
  }
  .submitButton {
    width: 100px;
  }
  .login-form {
    padding: 0 30px 30px 30px;
  }
  .linkLeftMargin {
    margin-left: 40px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .emailHeader {
    padding-top: 200px !important;
  }
  .resendEmailContainer {
    padding-top: 16px !important;
    padding-bottom: 73px !important;
  }
  .text-style {
    padding-top: 136px;
  }
  .logoContainer {
    margin-top: 148px;
  }
  .set-border-bottom {
    padding-bottom: 232px;
  }

  .emailText,
  .resetEmailText {
    width: 335px;
    margin: auto;
    padding: 15px 0px 17px 0px;
    font-size: 16px;
  }
  .resetEmailText {
    padding: 23px 0px 17px 0px;
  }
  .emailContainer {
    text-align: left;
    width: 336px;
    margin: auto;
  }
  .emailInput {
    width: 336px;
  }
  .submitButton {
    width: 25%;
    display: inline-block;
  }
  .linkLeftMargin {
    margin-left: 40px !important;
  }
}

.loaderContainer {
  display: flex;
  height: 45px;
}

.loader {
  margin-left: 5px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .loaderContainer {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .loaderContainer {
    justify-content: center;
  }
}
