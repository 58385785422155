.bannerBG {
  width: 100%;
  height: 390px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.bannerText {
  position: absolute;
  text-align: left;
  top: 250px;
  color: #fff;
  font-size: large;
  /* padding: 10px 40px; */
  word-wrap: normal;
  padding-right: 50px;
  padding-left: 20px;
}
