.main-panel {
  margin: inherit;
}

.checkoutContainer {
  padding: 0 20px 40px;
  background-color: white;
}

.checkoutContainer .paddedContent {
  padding: 0 35px 15px 0;
}

.orderHeading {
  padding: 16px 16px 0;
}

.product-image {
  width: 118px;
  float: left;
  margin-right: 20px;
  border-radius: 5px;
  overflow: hidden;
}

.product-image img {
  width: auto;
  height: 80px;
  margin: 0 auto;
  display: block;
}

.product-name-checkout {
  font-weight: bold;
  font-weight: 16px;
  color: black;
}

.product-info {
  line-height: 2;
}

.thead th {
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}

.thead .MuiTableCell-root {
  border-bottom: none;
  padding-bottom: 0;
}

.po-container {
  /* margin: 10px 20px; */
  margin: 0px 20px;
}

#padding {
  padding: 14px;
}
.continueShopping > a {
  margin: 0 20px;
  padding-right: 40px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #134c5d;
  text-decoration: underline;
}
.showmodal {
  padding: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .checkoutContainer .paddedContent {
    padding: 0;
  }
}
