.textReg {
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #3f4243;
  /* padding-top: 40%; */
}
.formPadding {
  padding: 30px;
}
.regFields {
  display: flex;
  justify-content: space-between;
  /* padding-top: 20px;
         padding-bottom: 20px; */
  /* padding-right: 20px; */
}
.saveButton {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.setMargin {
  margin-right: 10px;
  margin-left: 10px;
}
.buttonClass {
  margin-left: 10px;
  margin-bottom: 10px;
  width: 180 !important;
  height: 41 !important;
}
#billAddress {
  text-align: left;
  padding: 10px;
}
.regFields .makeStyles-margin-105 > label {
  padding-left: 4px;
}
.successFormPadding {
  padding: 30px;
}
.regFieldText
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiInputLabel-shrink {
  font-size: medium;
  font-weight: bold;
  color: #5b5e5f;
  text-align: left;
}
.MuiGrid-grid-xs-12 .regAddressSelect {
  margin-left: 15px;
  width: 100%;
}
.regAddressSelect .MuiFormControl-root {
  width: 100%;
}

.regFields
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4.MuiGrid-grid-md-4.MuiGrid-grid-lg-4 {
  margin-right: 2px;
}

.containerDiv {
  height: 100%;
}
.verticalcenterDiv {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
