.container {
  margin-top: 33px;
}
.centered {
  position: absolute;
  top: 40%;
  left: 5%;
  font-family: SourceSansPro;
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #ffffff;
  padding-left: 20px;
  text-align: left;
}

.imageResize {
  width: 100%;
  height: 430px;
  border-radius: 2px;
}
.setWidthCarousel {
  width: 100%;
  height: 430px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.setWidthCarousel img {
  object-fit: cover;
}

.btnClick {
  font-weight: normal;
  font-size: 16px;
  border-radius: 2px;
}
.centered .MuiButton-root {
  background-color: #e41e26 !important;
  font-weight: bold;
  size: medium;
  border: none;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;
}
.carousel.carousel-slider .control-arrow:hover {
  background-color: none;
}

.carousel.carousel-slider .centered h1,
.externalContainer.containerPromotion .centeredPromotion > h1 {
  text-shadow: 2px 2px 2px rgba(0, 0, 0.5); 
}


.externalContainer.containerPromotion .centeredPromotion > p {
  text-shadow: 1px 1px 2px rgba(0, 0, 0.7); 
}

.externalContainer.containerPromotion .centeredPromotion {
  background: rgba(0, 0, 0, 0.7);
}
