.MuiList-root {
  max-height: 200px;
  /* width: auto; */
}

.addAddress {
  display: flex;
  padding-top: 10px;
  float: right;
  padding-right: 10px;
}

.addressText {
  padding: 2px 10px 0px 10px;
}
