/* define a fixed width for the entire menu */
.navigationMob {
  width: 100%;
}

/* reset our lists to remove bullet points and padding */
.mainmenu,
.submenu,
.may {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  padding-left: 20px;
}

.mainmenu img {
  height: 16px;
  padding-right: 5px;
}

/* make ALL links (main and submenu) have padding and background color */
.mainmenu a {
  display: block;
  background-color: #fff;
  text-decoration: none;
  padding: 10px;
  color: #000;
}
.submenu {
  padding-left: 10px;
}
.may {
  padding-left: 20px;
}

/* add hover behaviour */
.mainmenu a:hover {
  background-color: #fff;
}

/* when hovering over a .mainmenu item,
display the submenu inside it.
we're changing the submenu's max-height from 0 to 200px;
*/

.mainmenu li:hover .submenu {
  display: block;
  max-height: 400px;
}
.submenu li:hover .may {
  display: block;
  max-height: 400px;
}

/*
we now overwrite the background-color for .submenu links only.
CSS reads down the page, so code at the bottom will overwrite the code at the top.
*/

.submenu a {
  background-color: #fff;
  /* background-color: #999; */
}

/* hover behaviour for links inside .submenu */
.submenu a:hover {
  /* background-color: #666; */
  background-color: #e41e26;
  color: #fff;
}

/* this is the initial state of all submenus.
we set it to max-height: 0, and hide the overflowed content.
*/
.submenu,
.may {
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s ease-out;
}
/* .may {
  overflow: hidden;
  max-height: 0;
  -webkit-transition: all 0.5s ease-out;
} */
