.select-input {
  margin-top: 10px;
}

.customized-panel {
  /* margin-top: 30px; */
  margin-top: 0;
  width: 100%;
}

.content-data {
  padding: 20px;
}

.content-data p {
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

ul.content-list li {
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.circular-progress-bar {
  text-align: center;
}

.content-list {
  padding: 20px 0 20px 40px;
}

.custom-error {
  font-weight: bold;
  font-size: 12px;
  color: red;
  font-style: italic;
}

.accordian-header {
  font-weight: bold;
  font-size: 18px;
}

.color-swatch {
  margin-top: 30px;
}

.color-box {
  margin-bottom: 10px;
}

.color-label {
  padding-bottom: 5px;
}

.dot {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}

.dot:hover {
  background-color: black;
  cursor: pointer;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
}

.color-name {
  font-weight: bold;
}

.color-seclected {
  -webkit-box-shadow: 11px 6px 18px -4px rgba(93, 97, 94, 1);
  -moz-box-shadow: 11px 6px 18px -4px rgba(93, 97, 94, 1);
  box-shadow: 11px 6px 18px -4px rgba(93, 97, 94, 1);
}

.width-100 {
  width: 100%;
}

.skuColor {
  font-size: 12px;
  text-align: center;
  color: #666666;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (max-width: 575px) {
  .color-swatch {
    margin-top: 20px;
  }

  .dot {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }
}

.custom-model {
  text-align: center;
}

/* iPads (portrait and landscape) ----------- */
@media (min-width: 576px) and (max-width: 767px) {
  .color-swatch {
    margin-top: 30px;
  }

  .accordian-header {
    font-size: 14px;
  }

  .customized-panel {
    margin-top: 0px;
    width: 100%;
  }
}

/* iPads (landscape) ----------- */
@media (min-width: 768px) and (max-width: 991px) {
  .color-swatch {
    margin-top: 30px;
  }

  .customized-panel {
    margin-top: 0px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .color-swatch {
    margin-top: 30px;
  }
}

/*Custom Carousel*/
.custom-carousel {
  width: 100%;
  padding: 10px 0 40px 0;
  margin-top: 30px;
  text-align: center;
}

.carousel-header {
  font-size: 24px;
  padding: 25px;
  font-weight: bold;
  margin: 0;
  line-height: 0;
}

.slick-prev {
  left: 2px;
  z-index: 1;
}

.slick-next {
  right: 6px;
}

.slick-next:before,
.slick-prev:before {
  font-size: 25px;
  line-height: 1;
  opacity: 1;
  color: black;
}

.carousel-image {
  width: 100%;
  height: 200px;
  margin: auto;
  padding: 10px 20px;
}

.card-container {
  border-radius: 4px;
  padding: 10px 5px;
}

.card {
  height: 360px;
  margin: 0 10px;
  padding-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #edf1f5;
  background-color: #ffffff;
}

.title-text {
  margin: 15px;
  padding: 0;
  font-size: 15px;
}

.price-text {
  margin: 0 10px 10px 10px;
  padding: 0;
}

@media (min-width: 320px) and (max-width: 576px) {
  .carousel-image {
    width: auto !important;
    height: 200px;
    margin: auto;
  }

  .customized-panel {
    margin-top: 0px;
    width: 100%;
  }

  .carousel-header {
    font-size: 20px;
    padding: 20px;
    font-weight: bold;
    margin: 0;
    line-height: 0;
  }

  .title-text {
    margin: 15px;
    padding: 0;
    font-size: 14px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .carousel-image {
    width: auto !important;
    height: 200px;
    margin: auto;
  }

  .title-text {
    margin: 15px;
    padding: 0;
    font-size: 14px;
  }
}

/* iPads (landscape) ----------- */
@media (min-width: 768px) and (max-width: 991px) {
  .carousel-image {
    width: auto !important;
    height: 200px;
    margin: auto;
  }
}

@media (min-width: 1200px) {
  .carousel-image {
    width: auto !important;
    height: 200px;
    margin: auto;
  }
}

/*End Custom Carousel*/

.shapeborder {
  border: 2px solid black;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
}

.circle {
  border-radius: 50%;
}

.outer {
  width: 50px;
  height: 50px;
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.inner {
  top: 4%;
  left: 4%;
  width: 44px;
  height: 44px;
  position: absolute;
  border: 1px solid #ced4da;
}

.inner:hover {
  cursor: pointer;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
}

/*No Outline Select CSS*/
.no-outline-select {
  padding-left: 15px;
}
.no-outline-select:focus {
  border: none;
  background: none;
}

.MuiBox-root > div {
  display: inline-block !important;
}

/*End No Outline Select CSS*/

/* React Pagination Start*/
.custom-pagination {
  display: inline-block;
}

.custom-pagination ul {
  display: inline-block;
}

.custom-pagination ul li {
  display: inline-block;
  padding: 0 8px;
}

.custom-pagination ul li a:focus {
  outline: 0;
  border: none;
}

.custom-pagination ul li.active {
  text-decoration: underline;
}

.custom-pagination ul li a {
  color: #939696;
  font-size: 12px;
  cursor: pointer;
}

.custom-pagination ul li .previousImg {
  display: inline-block;
  transform: rotate(180deg);
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #273142;
  position: relative;
  top: 3px;
  margin-right: 10px;
}

.custom-pagination ul li .nextImg {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #273142;
  position: relative;
  top: 3px;
  margin-left: 10px;
}

/* React Pagination end*/

/* Custom Cards start*/
.custom-card {
  height: 390px;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #edf1f5;
  background-color: #ffffff;
  margin: 0;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.overlay-list {
  position: absolute;
  /* top: 0; */
  /* left: 0; */
  width: 75%;
  height: 154px;
  /* height: 100%; */
  background: rgba(0, 0, 0, 0);
  -webkit-transition: background 0.5s ease;
  transition: background 0.5s ease;
}

.custom-card:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.6);
}

.button {
  position: absolute;
  width: 100%;
  left: 0;
  top: 180px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
}

.button a {
  width: 200px;
  padding: 12px 48px;
  text-align: center;
  color: white;
  border: solid 2px white;
  z-index: 1;
}

.button:hover a {
  color: white;
}

.button-list {
  position: absolute;
  width: 75%;
  justify-content: safe;
  margin-top: 70px;
  opacity: 0;
  -webkit-transition: opacity 0.35s ease;
  transition: opacity 0.35s ease;
}

.button-list a {
  width: 200px;
  padding: 12px 48px;
  text-align: center;
  color: white;
  border: solid 2px white;
  z-index: 1;
}

.button-list:hover a {
  color: white;
}

.custom-card-list:hover .overlay-list {
  display: block;
  background: rgba(0, 0, 0, 0.6);
}

.custom-card:hover .button {
  opacity: 1;
}

.custom-card-list:hover .button-list {
  opacity: 1;
}

.custom-card-list {
  height: 154px;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #edf1f5;
  background-color: #ffffff;
  margin: 5px;
  display: flex;
}

.card-item-name-list {
  padding: 20px 30px 20px 30px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.76px;
  text-align: left;
  color: #3f4143;
  height: 40px;
}

.list-bottom-line {
  display: flex;
  padding: 10px;
  /* text-align: left; */
}

.align-list {
  text-align: left;
  padding-left: 20px;
}

.card-item-price-list {
  font-size: 20px;
  text-align: center;
  color: #3f4143;
  padding: 5px 10px 0px 0px;
  margin-top: 5px;
}

.card-image-list img {
  padding: 10px 30px 10px 30px;
  height: 145px;
  width: 100%;
  border-radius: 4px;
}

.card-image {
  padding: 20px 30px;
  height: 170px;
}

.card-image img {
  width: auto;
  position: relative;
  border-radius: 4px;
}

.card-item-name {
  padding: 20px 30px 20px 30px;
  height: 100px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.76px;
  text-align: center;
  color: #3f4143;
}

.card-item-price {
  font-size: 20px;
  text-align: center;
  color: #3f4143;
  padding: 5px 10px;
  margin-top: 5px;
}

.quantity-stepper-box {
  margin-top: 20px;
}

.quantity-stepper-box p {
  margin: 0;
  padding: 0;
}

.product-quantity {
  color: #3f4243;
  display: inline-block;
}

.quantity-stepper1 {
  width: 135px;
  height: 30px;
  margin: auto;
  border-radius: 4px;
  border: solid 1px #dfe3e9;
  background-color: #ffffff;
  display: flex;
}

.quantity-field1 {
  width: 69px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  color: #5b5e5f;
  height: 29px;
  border: solid 1px #b0b1b2;
  padding-left: 0;
}

.minus,
.plus {
  width: 32px;
  height: 29px;
  border-radius: 4px;
  border: solid 1px #b0b1b2;
  background-color: #ffffff;
  cursor: pointer;
  padding-left: 0;
}

.minus {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.plus {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

@media (min-width: 576px) and (max-width: 767px) {
  .quantity-stepper1 {
    margin-top: 31px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .custom-card {
    height: 350px;
    margin: 5px;
  }

  .card-image {
    padding: 20px 35px 10px 35px;
    height: 175px;
  }

  .card-image img {
    width: auto;
    border-radius: 4px;
    height: 145px;
  }

  .card-item-name {
    padding: 10px 30px;
    height: 65px;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.76px;
    text-align: center;
    color: #3f4143;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card-image {
    padding: 20px 35px 10px 35px;
    height: 175px;
  }

  .card-image img {
    width: auto;
    border-radius: 4px;
    height: 145px;
  }

  .card-item-name {
    padding: 10px 30px;
    height: 65px;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.76px;
    text-align: center;
    color: #3f4143;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
  .custom-card {
    height: 390px;
    margin: 0 10px 15px 0;
  }

  .card-image {
    padding: 20px 35px 15px 35px;
    height: 210px;
  }

  .card-image img {
    width: auto;
    border-radius: 4px;
    height: 175px;
  }

  .card-item-name {
    padding: 10px 30px;
    height: 65px;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.76px;
    text-align: center;
    color: #3f4143;
  }

  .quantity-stepper1 {
    margin-top: 23px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
  .custom-card {
    height: 390px;
    margin: 0 10px 15px 0;
  }

  .card-item-name {
    padding: 20px 30px 20px 30px;
    height: 80px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.76px;
    text-align: center;
    color: #3f4143;
  }
}

@media (min-width: 1024px) {
  .custom-card {
    height: 390px;
    margin: 0 10px 15px 0;
  }

  .card-image {
    padding: 20px 40px 10px 40px;
    height: 205px;
  }

  .card-item-name {
    padding: 20px 35px 20px 35px;
    height: 80px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.76px;
    text-align: center;
    color: #3f4143;
  }

  .card-button {
    padding: 5px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .custom-card {
    height: 390px;
    margin: 5px;
  }

  .card-image {
    padding: 20px 30px 10px 30px;
    height: 175px;
  }

  .card-image img {
    width: auto;
    border-radius: 4px;
    height: 145px;
  }

  .card-item-name {
    padding: 20px 30px 20px 30px;
    height: 100px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.76px;
    text-align: center;
    color: #3f4143;
  }
}

/* Custom Cards end*/
/* Custome Page Loader */
#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: none;
}

#cover-modal {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(134, 127, 127, 0.3);
  z-index: 999;
  display: none;
  overflow: auto;
}

/* #cover-modal .product-dimensions {
  max-height: 100px;
  overflow-y: scroll;
} */

#cover-modal .color-swatch-box {
  overflow-y: auto;
  max-height: 100px;
}

.close-modal {
  float: right;
  margin: 40px 40px 0px 0px;
  cursor: pointer;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  height: 50%;
  width: 70%;
}

.modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.modal-body .product-details,
.product-feature {
  margin-top: 0px;
}

.modal-title {
  font-size: large;
  font-weight: 600;
  padding: 0px 20px 20px 0px;
  /* padding-left: 0px; */
}

.modal-data {
  display: flex;
}

.modal-img-container {
  text-align: center;
  width: 40%;
}

.modal-description {
  text-align: center;
  width: 60%;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: #e41e26;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.quick-searchcontainer {
  padding: 5px;
}

.product-quantity1 {
  color: #3f4243;
  display: inline-block;
  padding-right: 20px;
  font-size: medium;
  margin-top: 33px;
}

.inventoryLeft {
  display: block;
}

.display-list {
  margin-left: 10px;
  /* word-wrap: break-word; */
  height: auto;
  /* white-space: normal; */
  /* width: 100%; */
  padding-bottom: 10px;
  padding-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activeList {
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activeList,
.display-list:hover {
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, #edf1f5, #edf1f5);
  cursor: pointer;
  font-size: 14px;
}

.quick-searchcontainer {
  padding: 5px;
}
.quick-searchcontainer {
  padding: 5px;
}

/* order and quote list mobile layout  */

.listHeaders {
  font-weight: bold;
  padding: 11px;
}
