body {
  margin: 0;
  padding: 0;
  flex-wrap: unset;
  background-color: #fafbfc;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.centered-text {
  font-size: 18px;
  font-weight: bold;
  margin: 20px auto;
  text-align: center;
}

div {
  flex-wrap: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.css-1ed3p0v {
  z-index: 99999;
}
/* copy right text below footer */
.copyRight {
  text-align: center;
  color: #939696;
}

/* External container for component - shadow(material) */
.externalContainer {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.childComponentSpacing {
  padding-left: 5em;
  padding-right: 5em;
}

/* Header */
.header_bg {
  background: #fff;
  color: #222;
}
/* Added for global input field */
input {
  padding-left: 12px;
}

/* Media Queries */
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* STYLES GO HERE */
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }

  .imageResizePromotion {
    height: 325px;
    background-size: cover;
  }

  .carousel {
    height: 325px;
  }

  .centeredPromotion,
  .centeredPromotion p {
    color: #3f4143;
    text-align: left;
    width: 100%;
    padding-left: 0;
    left: 0;
  }

  .centeredPromotion {
    margin-bottom: 33px;
  }

  .setWidthPromotion {
    height: 325px;
    text-align: center;
    /* top: 50%; */
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }

  .paraText {
    color: #3f4143;
  }

  /* Customer support */
  /* STYLES GO HERE */
  .imageResizeCustomer {
    height: 325px;
    background-size: cover;
  }

  .carousel {
    height: 325px;
  }

  .centeredCustomer,
  .centeredCustomer p {
    color: #3f4143;
    text-align: left;
    width: 100%;
    padding-left: 0;
    left: 0;
  }

  .centeredCustomer {
    margin-bottom: 33px;
    background-color: #eff3f6;
  }

  .setWidthCustomer {
    height: 325px;
    text-align: center;
    /* top: 50%; */
  }

  .paraText {
    color: #3f4143;
  }

  .textCenter {
    margin-top: 0;
  }
  /* Added for promotion */
  .add-margin {
    margin-bottom: 33px;
  }
  .btnClickPromotion {
    padding-bottom: 20px;
  }
  /* Added for recommended products */
  .center-text {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 14px !important;
  }

  /* Added for featured categories */
  .responsive {
    width: 50%;
  }

  .view {
    display: inline-block;
  }

  .react-multi-carousel-list > ul > li:first-child {
    padding-left: 20px;
    flex: auto;
  }

  .react-multi-carousel-list > ul > li:nth-child(odd),
  .react-multi-carousel-list > ul > li:nth-child(even) {
    flex: 1 !important;
  }

  /* Added for login */
  .mob-button {
    width: 100%;
    margin-left: 0;
  }

  .text-format {
    width: 100%;
  }

  .login-form {
    padding: 30px;
  }

  .text-style {
    padding-top: 20%;
  }

  .set-border-bottom {
    padding-bottom: 200px;
  }

  /* Added for account */
  /* Added for account */
  /* .display-desktop{
            display: block;
        } */
  .account-management {
    text-align: center;
  }

  .tab-half {
    width: 50%;
  }

  #search {
    padding-bottom: 20px;
  }

  .border-set {
    border-bottom: solid 1px #dfe3e9;
    margin-top: 20px;
    padding: 10px;
  }

  .mobile-profile {
    border-radius: 4px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .search {
    width: 100%;
  }
  .set-height-rec {
    /* margin-top: 25px; */
  }
  /* Added for mini cart */
  .miniCart-position {
    width: 100%;
    left: 0;
  }
  .miniCart-layout {
    width: 100%;
  }
  .mob-padding {
    padding-left: 1em;
    padding-right: 1em;
  }
  /* Added for confirmation page  */
  .footer-icons {
    text-align: center;
  }
  /* Added for Home Hero Banner */
  .imageResize {
    height: 325px;
    background-size: cover;
  }

  .carousel {
    height: 325px;
  }

  .centered {
    text-align: center;
    width: 100%;
    padding-left: 0;
    left: 0;
  }

  .setWidthCarousel {
    height: 325px;
    text-align: center;
    /* top: 50%; */
  }
  /* Added for Update Profile */
  .account-button {
    width: 25%;
    /* background-color: rgb(228, 30, 38); */
    color: rgb(255, 255, 255);
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    padding: 5px;
  }
  /* Added for Registration */
  .regFields {
    display: block !important;
    padding: 0 !important;
  }
  /* .formPadding {
    padding: 0 !important;
  } */

  .saveButton {
    display: block !important;
    padding: 0 !important;
    margin-top: 20px !important;
  }
  .textReg {
    text-align: center !important;
  }
  .buttonClass {
    margin-bottom: 20px !important;
  }
  .MuiGrid-grid-xs-12 .regAddressSelect {
    margin-left: 9px !important;
    width: 103% !important;
  }
  .verticalcenterDiv {
    left: 0;
  }
  .footerMargin {
    margin-top: 100%;
    padding-top: 70%;
  }
  .view-details-btn-mob {
    flex-direction: column !important;
  }
  .view-details-btn {
    margin: 10px 0px !important;
    padding: 5px !important;
  }

  /* Added for order history page */
  .pagination-row {
    padding: 10px !important;
  }
  /* Added for first time login */
  .mobPadding {
    text-align: left !important;
  }
  /* Added for order history */
  .orderSearchPage {
    display: block !important;
    width: 100% !important;
    flex-wrap: wrap;
  }

  .selectDrop {
    width: 100%;
    /* margin-bottom: 15px; */
  }
  .selectOrder {
    padding: 0 !important;
    margin-bottom: 10px !important;
  }
  .page-title {
    text-transform: uppercase;
    line-height: 45px;
    font-weight: bold;
  }
  /* Added for Order filter */
  .labelCss {
    display: none;
  }
  /* Added for account contact search for mobile*/
  .search-container {
    display: flex;
  }
  .contactSearch {
    height: 50px;
    padding: 10px;
  }
  /* Added for Need us (contact) Page */
  .contactFields {
    display: block;
  }
  /* Added for maintenance page */
  .img-maintenance-set {
    background-size: contain !important;
    background-color: #ffffff;
    height: 100vh !important;
    background-repeat: no-repeat !important;
  }
  /* Added for dashboard latest orders */
  .latest-order-summary {
    display: flex;
    justify-content: space-around;
  }
  .common-margin {
    overflow-x: scroll;
  }
  /* Added for dashboard */
  .latest-orders {
    text-align: center;
  }
  .inner-head th {
    min-width: 205px;
  }
  .rowText td {
    min-width: 205px;
  }
  .addscrol {
    overflow-x: scroll;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* STYLES GO HERE */
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }
  /* STYLES GO HERE */
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* STYLES GO HERE */
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }

  .centeredPromotion {
    text-align: left;
    align-items: center;
    width: 100%;
    padding-left: 5%;
    margin-bottom: 33px;
    /* padding-right: 0; */
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }
  .centeredPromotion p {
    text-align: left;
  }

  /* customer support */
  /* STYLES GO HERE */
  .centeredCustomer {
    text-align: left;
    align-items: center;
    width: 100%;
    padding-left: 5%;
    margin-bottom: 33px;
    /* padding-right: 0; */
    height: 430px;
    background: black;
    opacity: 0.7;
    top: 0;
    left: 0;
    padding-left: 0;
  }

  .centeredCustomer p {
    text-align: left;
  }

  .textCenter {
    top: 40%;
    text-align: left;
    margin-top: 25%;
    padding-left: 5%;
  }

  .paraText {
    text-align: left;
  }

  /* Added for recommended products */
  .center-text {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 14px;
  }

  /* Added for login */
  .text-style {
    padding-top: 20%;
  }

  .set-border-bottom {
    padding-bottom: 200px;
  }

  /* Added for account */
  .company-wrapper {
    margin-left: 0;
  }

  .display-desktop {
    display: flex;
  }
  /* Added for Home Hero Banner */
  .centered {
    text-align: center;
    align-items: center;
    width: 100%;
    padding-left: 0;
    /* padding-right: 0; */
    left: 0;
  }
  /* Added for checkout page */
  .quantity-wrapper input {
    /* text-align: left; */
  }
  /* Added for Registration */
  .saveButton {
    display: block !important;
    padding: 0;

    text-align: left;
  }
  .textReg {
    text-align: center !important;
  }
  .buttonClass {
    margin-bottom: 20px !important;
  }
  /* .saveButton {
    display: block !important;
    padding: 0;
  }
  .formPadding {
    padding: 0;
  } */
  .verticalcenterDiv {
    left: 25%;
  }
  .footerMargin {
    margin-top: 100%;
    padding-top: 0;
  }
  /* Added for First time login */
  .mobPadding {
    text-align: left !important;
  }
  .page-title {
    text-transform: uppercase;
    line-height: 45px;
    font-weight: bold;
  }
  /* Added for order filter */
  .labelCss {
    display: none;
  }
  .selectDrop,
  .selectOrder,
  .orderSearchPage {
    width: 100%;
  }
  .selectOrder {
    padding: 0 !important;
    margin-bottom: 10px !important;
  }
  /* Added for Need us (contact) Page */
  .contactFields {
    display: block !important;
  }
  /* Added for maintenance page */
  .img-maintenance-set {
    background-size: contain !important;
    background-color: #ffffff;
    height: 100vh !important;
    background-repeat: no-repeat !important;
  }
  /* Added for dashboard latest orders */
  .latest-order-summary {
    display: flex;
    justify-content: space-around;
  }
  .common-margin {
    overflow-x: scroll;
  }
  .latest-orders {
    text-align: center;
  }

  .outerTable td:nth-child(2),
  td:nth-child(3) td:nth-child(4) {
    width: 0%;
  }

  .outerTable th:nth-child(1),
  .outerTable th:nth-child(5),
  .outerTable th:nth-child(6),
  .outerTable th:nth-child(7) {
    width: 25%;
  }
  .inner-head th {
    min-width: 275px;
  }

  .rowText td {
    min-width: 275px;
  }
  .add-border {
    border: none;
  }
  .addscrol {
    overflow-x: scroll;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }
  /* STYLES GO HERE */
  .centeredPromotion {
    position: absolute;
    color: #ffffff;
    align-items: left;
    width: 100%;
    padding-left: 5%;
    margin-bottom: 33px;
    /* padding-right: 0; */
  }

  .centeredPromotion p {
    text-align: left;
  }

  /* STYLES GO HERE */
  .centeredCustomer {
    position: absolute;
    color: #ffffff;
    align-items: left;
    width: 100%;
    padding-left: 5%;
    margin-bottom: 33px;
    /* padding-right: 0; */
    height: 430px;
    background: black;
    opacity: 0.7;
    top: 0;
    left: 0;
    padding-left: 0;
  }

  .centeredCustomer p {
    text-align: left;
  }

  .textCenter {
    top: 40%;
    text-align: left;
    margin-top: 25%;
    padding-left: 5%;
  }

  .paraText {
    text-align: left;
  }

  /* Added for recommended products */
  .center-text {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 14px;
  }

  /* Added for login */
  .text-style {
    padding-top: 20%;
  }

  .set-border-bottom {
    padding-bottom: 200px;
  }
  /* Added for account */
  #search {
    padding-right: 20px;
  }
  /* Added for Home Hero Banner */
  .centered {
    align-items: center;
    width: 100%;
    padding-left: 0;
    /* padding-right: 0; */
    left: 0;
  }
  /* Added for checkout page */
  .quantity-wrapper input {
    /* text-align: left; */
  }
  /* Added for maintenance page */
  .img-maintenance-set {
    background-size: contain !important;
    background-color: #ffffff;
    height: 100vh !important;
    background-repeat: no-repeat !important;
  }
  /* Added for dashboard */
  .latest-orders {
    text-align: center;
  }
  .add-border {
    border: none;
  }
  .outerTable th:nth-child(7) {
    padding-top: 16px !important;
  }

  .addscrol {
    overflow-x: scroll;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }
  /* STYLES GO HERE */
  .centeredPromotion {
    position: absolute;
    color: #ffffff;
    align-items: left;
    width: 100%;
    padding-left: 0;
    /* padding-right: 0; */
  }

  /* customer support */

  /* STYLES GO HERE */
  .centeredCustomer {
    position: absolute;
    color: #ffffff;
    align-items: left;
    width: 100%;
    padding-left: 0;
    /* padding-right: 0; */
    width: 50%;
    height: 430px;
    background: black;
    opacity: 0.7;
    top: 0;
    left: 0;
  }

  .textCenter {
    top: 40% !important;
    text-align: left !important;
    margin-top: 25% !important;
    padding-left: 5% !important;
  }

  /* Added for recommended products */
  .center-text {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 14px;
  }

  /* Added for checkout page */
  .quantity-wrapper input {
    /* text-align: left; */
  }
  /* Added for dashboard */
  .latest-orders {
    text-align: center;
  }
  .outerTable th:nth-child(7) {
    padding-top: 16px !important;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 800px) {
  /* STYLES GO HERE */
  .childComponentSpacing {
    padding-left: 5em;
    padding-right: 5em;
  }

  .centeredPromotion {
    position: absolute;
    color: #ffffff;
    top: 40%;
    left: 5%;
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }
  /* customer support */
  /* STYLES GO HERE */
  .centeredCustomer {
    position: absolute;
    color: #ffffff;
    width: 50%;
    height: 430px;
    background: black;
    opacity: 0.7;
    top: 0;
    left: 0;

    /* top: 40%;
          left:5%; */
  }

  /* Added for recommended products */
  .center-text {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 14px;
  }

  .react-multi-carousel-list > ul > li:first-child {
    /* padding-left: 20px; */
    flex: 1 !important;
  }

  .react-multi-carousel-list > .react-multi-carousel-track > li {
    margin: 5px;
  }

  /* Added for login */
  .align-center {
    text-align: left;
  }

  .text-style {
    text-align: left;
  }

  /* Added for account */
  .display-desktop {
    display: flex;
    padding-left: 15px;
  }
  .contactFields {
    display: flex;
  }
  /* added for maitenance page */
  .img-maintenance-set {
    background-size: cover !important;
    background-color: #ffffff;
    height: 100vh !important;
    background-repeat: no-repeat !important;
  }
  /* added for dashboard */
  .display-prop {
    display: flex;
  }
  .orderHeader {
    display: block;
    /* justify-content: center; */
  }
  /* .inner-head th,
  .rowText td {
    width:20%;
  } */
  .removeFlexWrap {
    flex-wrap: wrap !important;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* STYLES GO HERE */
  /* Added for Home Hero Banner */
  .centered {
    top: 40%;
    left: 5%;
  }
  /* added for dashboard */
  .display-prop {
    display: flex;
  }
  .orderHeader {
    display: block;
    /* justify-content: center; */
  }
  .inner-head th,
  .rowText td {
    width: 20%;
  }
  .removeFlexWrap {
    flex-wrap: nowrap !important;
  }
}
@media only screen and (min-width: 800px) {
  .inner-head th,
  .rowText td {
    width: 20%;
  }
}
@media only screen and (min-width: 1180px) {
  .inner-head th,
  .rowText td {
    width: 20%;
  }
}
/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* STYLES GO HERE */
  .childComponentSpacing {
    padding-left: 5em;
    padding-right: 5em;
  }
  /* added for dashboard */
  .display-prop {
    display: flex;
  }
  .orderHeader {
    display: block;
    /* justify-content: center; */
  }
  .inner-head th,
  .rowText td {
    width: 20%;
  }
  .removeFlexWrap {
    flex-wrap: nowrap !important;
  }
}

/* iPhone 5 (portrait &amp; landscape)----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  /* STYLES GO HERE */
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }

  /* customer support */

  .setWidthCustomer {
    height: 325px;
  }
}

/* iPhone 5 (landscape)----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) {
  /* STYLES GO HERE */
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }

  .setWidthPromotion {
    height: 325px;
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }
  /* Added for Home Hero Banner */
  .setWidthCarousel {
    height: 325px;
  }
}

/* iPhone 5 (portrait)----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) {
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }
  /* STYLES GO HERE */
}

/* style change for toast message */
.css-1bm1z97-ToastContainer {
  z-index: 99999;
  margin-top: 65px !important;
}

.css-h2fnfe {
  /* z-index: 99999; */
  margin-top: 65px !important;
}
