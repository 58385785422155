.showError {
  border: 2px solid #ff0000;
}

.right-align-search {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.orderProd {
  width: 100%;
  background-color: #ffffff;
  border: 5px black;
  height: 100%;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  margin-top: 20px;
  padding: 20px;
}

.orderSearch {
  width: 450px;
  margin: auto;
}

.orderProd ul {
  position: absolute;
  width: 450px;
}

.cards {
  height: auto;
  width: 100%;
}

#addToQuote {
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.32);
  background: #e41e26;
  color: #ffffff;
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
  margin: auto;
}

.margin-bottom {
  margin-bottom: 20px;
}

.element-width {
  width: 100%;
}

.element-width>div {
  width: 40%;
  display: inline-block;
}

.bottomButton {
  /* display: flex; */
  /* float: right; */
}

/* Added for upload file */

* {
  box-sizing: border-box;
  font-family: "Helvetica", sans-serif;
}

.container {
  text-align: center;
  padding: 15px;
  margin: 10px auto;
}

.csv-input {
  visibility: hidden;
}
.react-csv-input label {
  padding: 11px;
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-right: 10px;
  background: #e41e26;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
}
.container p {
  padding-top: 10px;
  color: #666;
}

.padLeft {
  padding-left: 10px
}

.padRight {
  padding-right: 10px
}

.button1 {
  padding: 10px;
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-right: 10px;
  background: #e41e26;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
}

.importFile {
  display: flex;
  padding-bottom: 10px;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .orderProd {
    padding: 6px;
  }

  .orderSearch {
    width: 100%;
  }

  .orderProd ul {
    width: 90%;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .orderProd {
    padding: 6px;
  }

  .orderSearch {
    width: 400px;
  }

  .orderProd ul {
    width: 400px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .element-width>div {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .element-width>div {
    width: 60%;
  }
}
