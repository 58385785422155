.navigation {
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 1;
}
.navigation img {
  vertical-align: middle;
  padding-right: 5px;
}
.navigation span {
  vertical-align: super;
}
.navigation ul,
.navigation ul li,
.navigation ul ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation ul {
  position: relative;
  z-index: 500;
  float: left;
}

.navigation ul li {
  float: left;
  min-height: 0.05em;
  line-height: 1em;
  vertical-align: middle;
  position: relative;
  /* box-shadow: 1px 1px 1px 1px #2222; */
  padding-top: 15px;
  padding-bottom: 15px;
}

.navigation ul li.hover,
.navigation ul li:hover {
  position: relative;
  z-index: 510;
  cursor: default;
}

.navigation ul ul {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: 520;
  width: 100%;
}

.navigation ul ul li {
  float: none;
  transition: all 0.5s ease-in;
}

.navigation ul ul ul {
  top: 0;
  right: 0;
}

.navigation ul li:hover > ul {
  visibility: visible;
}

.navigation ul ul {
  top: 0;
  left: 99%;
}

.navigation ul li {
  float: none;
}

.navigation ul ul {
  margin-top: 0.05em;
}

.navigation {
  /* width: 15em; */
  /* width: 100%; */
  /* background: #333333; */
  background: #ffffff;
  /* font-family: 'roboto', Tahoma, Arial, sans-serif; */
  zoom: 1;
}

.navigation:before {
  content: '';
  display: block;
}

.navigation:after {
  content: '';
  display: table;
  clear: both;
}

.navigation a {
  display: block;
  padding: 1em 1.3em;
  color: #222222;
  text-decoration: none;
  text-transform: uppercase;
}

/* .navigation > ul { width: 13em; }
  
  .navigation ul ul { width: 13em; } */

.navigation > ul {
  width: 100%;
}

.navigation ul ul {
  width: 100%;
}

.navigation > ul > li > a {
  /* border-right: 0.3em solid #34A65F; */
  color: #222222;
}

.navigation > ul > li > a:hover {
  color: #222222;
}

.navigation > ul > li a:hover,
  /* .navigation > ul > li:hover a { background: #e41e26; } */
  
  .navigation li {
  position: relative;
  background: #ffffff;
  z-index: 99999;
}

.navigation ul li.has-sub > a:after {
  /* content: '>'; */
  content: '\003e';
  position: absolute;
  right: 1em;
}

.navigation ul ul li.first {
  -webkit-border-radius: 0 3px 0 0;
  -moz-border-radius: 0 3px 0 0;
  border-radius: 0 3px 0 0;
}

.navigation ul ul li.last {
  -webkit-border-radius: 0 0 3px 0;
  -moz-border-radius: 0 0 3px 0;
  border-radius: 0 0 3px 0;
  border-bottom: 0;
}

.navigation ul ul {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

/* .navigation ul ul { border: 1px solid #e41e26; } */

.navigation ul ul a {
  color: #333333;
}

.navigation ul ul a:hover {
  color: #222222;
}

/* .navigation ul ul li { border-bottom: 1px solid #e41e26; } */

.navigation ul ul li:hover > a {
  background: #e41e26;
  /* background: #2ec2ce; */
  color: #ffffff;
}

.navigation.align-right > ul > li > a {
  border-left: 0.3em solid #e41e26;
  border-right: none;
}

.navigation.align-right {
  float: right;
}

.navigation.align-right li {
  text-align: right;
}

.navigation.align-right ul li.has-sub > a:before {
  content: '+';
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -6px;
}

.navigation.align-right ul li.has-sub > a:after {
  content: none;
}

.navigation.align-right ul ul {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 598;
  width: 100%;
}

.navigation.align-right ul ul li.first {
  -webkit-border-radius: 3px 0 0 0;
  -moz-border-radius: 3px 0 0 0;
  border-radius: 3px 0 0 0;
}

.navigation.align-right ul ul li.last {
  -webkit-border-radius: 0 0 0 3px;
  -moz-border-radius: 0 0 0 3px;
  border-radius: 0 0 0 3px;
}

.navigation.align-right ul ul {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

ul {
  /* box-shadow: 1px 1px 1px 1px #2222 ; */
}

.customFooter ul li {
  text-decoration: none;
  list-style: none;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: pointer;
}
.dFooter ul li > a {
  padding-top: 0;
  padding-bottom: 0;
  color: #939696;
}

.navigation li {
  transition: all 2s ease-in-out;
}

.customFontAwesome {
  color: #555555a1;
  padding-right: 5px;
}
.buildInfo {
  display: none;
}
