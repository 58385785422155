.search-field-invoice {
  position: absolute;
  display: inline-block;
  text-align: right;
  right: 5em;
}
.paymentStatus {
  float: right;
}
.customPadding {
  padding-bottom: 1em;
}
.alignRight {
  text-align: right !important;
}
