.highLightElement {
  border: 2px solid #ff0000;
}
.main-panel {
  margin: inherit;
}

.checkoutContainer {
  padding: 0 20px;
}

.cartWrapper .paddedContent {
  padding: 0 0px 15px 0 !important;
}
.paddedContent {
  width: 100%;
}

.orderHeading {
  padding: 16px 16px 0;
}

.product-image {
  width: 25%;
  float: left;
  margin-right: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.product-image img {
  width: auto;
  height: 80px;
  margin: 0 auto;
  display: block;
}

.product-name-checkout {
  font-weight: bold;
  font-weight: 16px;
  color: black;
}

.product-info {
  line-height: 2;
}

.thead th {
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}

.thead .MuiTableCell-root {
  border-bottom: none;
  padding-bottom: 0;
}

.alert-text-container {
  width: 100%;
}

.alert-text {
  font: message-box;
}

.bepsy-alert-button {
  padding: 40px 5px 0px;
}

#cart .modal-content {
  height: 150px;
  width: 350px;
}

#cart .modal-body {
  padding: 20px;
}
.flexDisplay {
  display: flex;
  justify-content: space-between;
}
