.order-summary-container {
  background-color: #eff3f6;
  padding: 0 0 16px;
}
.order-summary-container td,
.order-summary-container th {
  border-bottom: none;
}
.order-summary-container .order-summary-tbody {
  padding: 10px 0;
}
.order-summary-container .order-summary-tbody tr:nth-child(n) td {
  padding: 5px 16px;
}
.order-summary-container
  .order-summary-tbody
  tr:nth-child(n)
  td:first-child {
  font-weight: bold;
}
.order-summary-container
  .order-summary-tbody
  tr:nth-child(n)
  td:last-child {
  text-align: right;
}
.order-summary-container
  .order-summary-tbody
  tr:nth-child(n)
  td:last-child:before {
  content: '$';
}
.order-summary-container
  .order-summary-tfooter
  tr:nth-child(1)
  td:first-child {
  font-weight: bold;
}
.order-summary-container
  .order-summary-tfooter
  tr:nth-child(1)
  td:last-child {
  text-align: right;
  font-weight: bold;
}
.order-summary-container
  .order-summary-tfooter
  tr:nth-child(1)
  td:last-child:before {
  content: '$';
}
.order-summary-container .divider {
  padding: 0 16px;
}
.order-summary-container .placeorder-btn {
  background-color: #273142;
  color: white;
  font-weight: bold;
}
.order-summary-container .placeorder-btn:hover {
  color: black;
}
/* Added for email cart */
.email-cart {
  border-radius: 2px;
  border: solid 1px #dfe3e9;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 36px !important;
  background-color: #ffffff !important;
  box-sizing: border-box;
  width: 100%;
  padding: 2px;
}

.display-property{  
  display: flex;
  justify-content: center;
}
.align-div{
  text-align: center;
}
