.containerPromotion {
  margin-top: 33px;
  position: relative;
  display: inline-block;
  width: 100%;
}
.centeredPromotion {
  top: 40%;
  left: 5%;
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #3f4143;
  text-align: left;
}
.imageResizePromotion {
  width: 100%;
  height: 430px;
  border-radius: 2px;
}
.setWidthPromotion {
  width: 100%;
  height: 430px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.setWidthPromotion img {
  object-fit: cover;
  width: 100%;
}
.btnClickPromotion {
  font-weight: normal;
  font-size: 16px;
  border-radius: 2px;
}
.centeredPromotion p {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  width: 50%;
}
.centeredPromotion .MuiButton-root {
  background-color: #e41e26 !important;
  font-weight: bold;
  size: medium;
  border: none;
}
