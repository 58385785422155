.customHeader {
  height: 70px;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    0 2px 5px 0 rgba(0, 0, 0, 0.05);

  background-color: #ffffff;

  color: #222222;
}

.logo-container img {
  width: 140px;
}

.logo-container.ppg img {
  width: auto;
  height: 40px;
}

.mobileMenu {
  position: absolute;
  top: 71px;
  z-index: 99999;
}
.show-minicart {
  position: relative;
  z-index: 3;
}
.locale-container {
  position: absolute;
  right: 10rem;
}

.miniCart {
  position: absolute;
  right: 5em;
}

.miniCart-position {
  top: 5em;
}

.miniCart-layout {
  background: #ffffff;
  height: 400px;
  width: 300px;
  z-index: 9999999999;
  position: relative;
}

.headerMinicart {
  cursor: pointer;
  color: black;
}

/* added from  minicart layout */
.miniCart-position {
  position: absolute;
  right: 0;
  top: 20px;
  border-radius: 3px;
}

.miniCart-layout {
  background: #ffffff;
  height: 290px;
  width: 335px;
  z-index: 9999999999;
  position: relative;
  border-radius: 4px;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #edf1f5; */
  overflow-y: scroll;
}

.card-style {
  margin: 1em;
  padding: 1em;
}

.mini-cart-img {
  height: 65px;
  width: 50px;
  margin: 0 auto;
  border-radius: 4px;
  padding: 5px;
}

.product-text {
  /* font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.76px;
  color: #3f4143; */
  padding: 5px;
}

.productPriceCart {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #3f4143;
  padding-top: 5px;
}

.mini-cart-details {
  padding-left: 5px;
  padding-right: 5px;
}

.qty {
  float: left;
}

.sale-price {
  float: right;
}

.view-button {
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.32);
  background-color: #273142 !important;
  float: right;
  margin: 1em !important;
  padding: 1em !important;
  height: 38px;
  position: static;
}

.show-text {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
}

/* For minicart hover */
.miniCart-position {
  visibility: hidden;
  z-index: 1;
}

.miniCart:hover .miniCart-position {
  visibility: visible;
}

.set-cs {
  padding-top: 18px;
}

.button-wrap {
  z-index: 9999;
  background-color: white;
  position: absolute;
  float: right;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.navigation {
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 1;
}
.navigation img {
  vertical-align: middle;
  padding-right: 5px;
}
.navigation span {
  vertical-align: super;
}
.navigation ul,
.navigation ul li,
.navigation ul ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation ul {
  position: relative;
  z-index: 500;
  float: left;
}

.navigation ul li {
  float: left;
  min-height: 0.05em;
  line-height: 1em;
  vertical-align: middle;
  position: relative;
  /* box-shadow: 1px 1px 1px 1px #2222; */
  padding-top: 15px;
  padding-bottom: 15px;
}

.navigation ul li.hover,
.navigation ul li:hover {
  position: relative;
  z-index: 510;
  cursor: default;
}

.navigation ul ul {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: 520;
  width: 100%;
}

.navigation ul ul li {
  float: none;
  transition: all 0.5s ease-in;
}

.navigation ul ul ul {
  top: 0;
  right: 0;
}

.navigation ul li:hover > ul {
  visibility: visible;
}

.navigation ul ul {
  top: 0;
  left: 99%;
}

.navigation ul li {
  float: none;
}

.navigation ul ul {
  margin-top: 0.05em;
}

.navigation {
  /* width: 15em; */
  /* width: 100%; */
  /* background: #333333; */
  background: #ffffff;
  /* font-family: 'roboto', Tahoma, Arial, sans-serif; */
  zoom: 1;
}

.navigation:before {
  content: '';
  display: block;
}

.navigation:after {
  content: '';
  display: table;
  clear: both;
}

.navigation a {
  display: block;
  padding: 1em 1.3em;
  color: #222222;
  text-decoration: none;
  text-transform: uppercase;
}

/* .navigation > ul { width: 13em; }
  
  .navigation ul ul { width: 13em; } */

.navigation > ul {
  width: 100%;
}

.navigation ul ul {
  width: 100%;
}

.navigation > ul > li > a {
  /* border-right: 0.3em solid #34A65F; */
  color: #222222;
}

.navigation > ul > li > a:hover {
  color: #222222;
}

.navigation > ul > li a:hover,
  /* .navigation > ul > li:hover a { background: #e41e26; } */
  
  .navigation li {
  position: relative;
  background: #ffffff;
  z-index: 99999;
}

.navigation ul li.has-sub > a:after {
  /* content: '>'; */
  content: '>';
  position: absolute;
  right: 1em;
}

.navigation ul ul li.first {
  border-radius: 0 3px 0 0;
}

.navigation ul ul li.last {
  border-radius: 0 0 3px 0;
  border-bottom: 0;
}

.navigation ul ul {
  border-radius: 0 3px 3px 0;
}

/* .navigation ul ul { border: 1px solid #e41e26; } */

.navigation ul ul a {
  color: #333333;
}

.navigation ul ul a:hover {
  color: #222222;
}

/* .navigation ul ul li { border-bottom: 1px solid #e41e26; } */

.navigation ul ul li:hover > a {
  background: #e41e26;
  /* background: #2ec2ce; */
  color: #ffffff;
}

.navigation.align-right > ul > li > a {
  border-left: 0.3em solid #e41e26;
  border-right: none;
}

.navigation.align-right {
  float: right;
}

.navigation.align-right li {
  text-align: right;
}

.navigation.align-right ul li.has-sub > a:before {
  content: '+';
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -6px;
}

.navigation.align-right ul li.has-sub > a:after {
  content: none;
}

.navigation.align-right ul ul {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 598;
  width: 100%;
}

.navigation.align-right ul ul li.first {
  border-radius: 3px 0 0 0;
}

.navigation.align-right ul ul li.last {
  border-radius: 0 0 0 3px;
}

.navigation.align-right ul ul {
  border-radius: 3px 0 0 3px;
}

ul {
  /* box-shadow: 1px 1px 1px 1px #2222 ; */
}

.customFooter ul li {
  text-decoration: none;
  list-style: none;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: pointer;
}
.dFooter ul li > a {
  padding-top: 0;
  padding-bottom: 0;
  color: #939696;
}

.navigation li {
  transition: all 2s ease-in-out;
}

.customFontAwesome {
  color: #555555a1;
  padding-right: 5px;
}
.buildInfo {
  display: none;
}


.miniFooter span {
    border-right: 0.01em solid #939696;
    padding-right: 0.5em;
    padding-left: 0.5em;
    cursor: pointer;
    color: #939696;
}

.miniFooter span:last-child { border-right: 0px; } 
  
/* define a fixed width for the entire menu */
.navigationMob {
  width: 100%;
}

/* reset our lists to remove bullet points and padding */
.mainmenu,
.submenu,
.may {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  padding-left: 20px;
}

.mainmenu img {
  height: 16px;
  padding-right: 5px;
}

/* make ALL links (main and submenu) have padding and background color */
.mainmenu a {
  display: block;
  background-color: #fff;
  text-decoration: none;
  padding: 10px;
  color: #000;
}
.submenu {
  padding-left: 10px;
}
.may {
  padding-left: 20px;
}

/* add hover behaviour */
.mainmenu a:hover {
  background-color: #fff;
}

/* when hovering over a .mainmenu item,
display the submenu inside it.
we're changing the submenu's max-height from 0 to 200px;
*/

.mainmenu li:hover .submenu {
  display: block;
  max-height: 400px;
}
.submenu li:hover .may {
  display: block;
  max-height: 400px;
}

/*
we now overwrite the background-color for .submenu links only.
CSS reads down the page, so code at the bottom will overwrite the code at the top.
*/

.submenu a {
  background-color: #fff;
  /* background-color: #999; */
}

/* hover behaviour for links inside .submenu */
.submenu a:hover {
  /* background-color: #666; */
  background-color: #e41e26;
  color: #fff;
}

/* this is the initial state of all submenus.
we set it to max-height: 0, and hide the overflowed content.
*/
.submenu,
.may {
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s ease-out;
}
/* .may {
  overflow: hidden;
  max-height: 0;
  -webkit-transition: all 0.5s ease-out;
} */

* {
  margin: 0;
  padding: 0;
}

.main-panel {
  padding: 30px 40px;
  position: relative;
}

.search-header {
  position: relative;
  padding-bottom: 5px;
  top: -10px;
  margin-bottom: 4em;
  width: 100%;
}

.search-header h2 {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  line-height: 2.5;
  letter-spacing: 0.76px;
  color: #273142;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.search-field {
  position: absolute;
  display: inline-block;
  text-align: right;
  right: 0;
}

@media (max-width: 575px) {
  .main-panel {
    padding: 15px 20px;
  }

  .search-header {
    /* top: -7px; */
    margin-bottom: 4em;
  }

  .search-header h2 {
    font-size: 14px;
  }

  .search-field {
    /* removed to show thw complete search on mobile */
    width: 100%;
    margin-bottom: 10px;
  }

  .search-field .makeStyles-root-174 {
    height: 35px !important;
  }
}

/* iPads (portrait and landscape) ----------- */
@media (min-width: 576px) and (max-width: 767px) {
  .main-panel {
    padding: 25px 30px;
  }
  .search-field {
    width: 60%;
  }
}

/* iPads (landscape) ----------- */
@media (min-width: 768px) and (max-width: 1199px) {
  .main-panel {
    padding: 30px 40px;
  }
  .search-field {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .main-panel {
    padding: 30px 40px;
  }
  .search-field {
    width: 40%;
  }
}

.container {
  margin-top: 33px;
}
.centered {
  position: absolute;
  top: 40%;
  left: 5%;
  font-family: SourceSansPro;
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #ffffff;
  padding-left: 20px;
  text-align: left;
}

.imageResize {
  width: 100%;
  height: 430px;
  border-radius: 2px;
}
.setWidthCarousel {
  width: 100%;
  height: 430px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.setWidthCarousel img {
  object-fit: cover;
}

.btnClick {
  font-weight: normal;
  font-size: 16px;
  border-radius: 2px;
}
.centered .MuiButton-root {
  background-color: #e41e26 !important;
  font-weight: bold;
  size: medium;
  border: none;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;
}
.carousel.carousel-slider .control-arrow:hover {
  background-color: none;
}

.carousel.carousel-slider .centered h1,
.externalContainer.containerPromotion .centeredPromotion > h1 {
  text-shadow: 2px 2px 2px rgba(0, 0, 0.5); 
}


.externalContainer.containerPromotion .centeredPromotion > p {
  text-shadow: 1px 1px 2px rgba(0, 0, 0.7); 
}

.externalContainer.containerPromotion .centeredPromotion {
  background: rgba(0, 0, 0, 0.7);
}

.containerPromotion {
  margin-top: 33px;
  position: relative;
  display: inline-block;
  width: 100%;
}
.centeredPromotion {
  top: 40%;
  left: 5%;
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #3f4143;
  text-align: left;
}
.imageResizePromotion {
  width: 100%;
  height: 430px;
  border-radius: 2px;
}
.setWidthPromotion {
  width: 100%;
  height: 430px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.setWidthPromotion img {
  object-fit: cover;
  width: 100%;
}
.btnClickPromotion {
  font-weight: normal;
  font-size: 16px;
  border-radius: 2px;
}
.centeredPromotion p {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  width: 50%;
}
.centeredPromotion .MuiButton-root {
  background-color: #e41e26 !important;
  font-weight: bold;
  size: medium;
  border: none;
}

.containerCustomer {
  margin-top: 33px;
  margin-bottom: 33px;
  position: relative;
  display: inline-block;
  width: 100%;
}
.centeredCustomer {
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #3f4143;
  text-align: left;
}
.imageResizeCustomer {
  width: 100%;
  height: 430px;
  border-radius: 2px;
}
.setWidthCustomer {
  width: 100%;
  height: 430px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.btn-click-customer {
  font-weight: normal;
  font-size: 16px;
  border-radius: 2px;
}
.centeredCustomer p {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
}
.textCenter {
  top: 40%;
  text-align: left;
  margin-top: 25%;
  padding-left: 5%;
}

.select-input {
  margin-top: 10px;
}

.customized-panel {
  /* margin-top: 30px; */
  margin-top: 0;
  width: 100%;
}

.content-data {
  padding: 20px;
}

.content-data p {
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

ul.content-list li {
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.circular-progress-bar {
  text-align: center;
}

.content-list {
  padding: 20px 0 20px 40px;
}

.custom-error {
  font-weight: bold;
  font-size: 12px;
  color: red;
  font-style: italic;
}

.accordian-header {
  font-weight: bold;
  font-size: 18px;
}

.color-swatch {
  margin-top: 30px;
}

.color-box {
  margin-bottom: 10px;
}

.color-label {
  padding-bottom: 5px;
}

.dot {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}

.dot:hover {
  background-color: black;
  cursor: pointer;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
}

.color-name {
  font-weight: bold;
}

.color-seclected {
  box-shadow: 11px 6px 18px -4px rgba(93, 97, 94, 1);
}

.width-100 {
  width: 100%;
}

.skuColor {
  font-size: 12px;
  text-align: center;
  color: #666666;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (max-width: 575px) {
  .color-swatch {
    margin-top: 20px;
  }

  .dot {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }
}

.custom-model {
  text-align: center;
}

/* iPads (portrait and landscape) ----------- */
@media (min-width: 576px) and (max-width: 767px) {
  .color-swatch {
    margin-top: 30px;
  }

  .accordian-header {
    font-size: 14px;
  }

  .customized-panel {
    margin-top: 0px;
    width: 100%;
  }
}

/* iPads (landscape) ----------- */
@media (min-width: 768px) and (max-width: 991px) {
  .color-swatch {
    margin-top: 30px;
  }

  .customized-panel {
    margin-top: 0px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .color-swatch {
    margin-top: 30px;
  }
}

/*Custom Carousel*/
.custom-carousel {
  width: 100%;
  padding: 10px 0 40px 0;
  margin-top: 30px;
  text-align: center;
}

.carousel-header {
  font-size: 24px;
  padding: 25px;
  font-weight: bold;
  margin: 0;
  line-height: 0;
}

.slick-prev {
  left: 2px;
  z-index: 1;
}

.slick-next {
  right: 6px;
}

.slick-next:before,
.slick-prev:before {
  font-size: 25px;
  line-height: 1;
  opacity: 1;
  color: black;
}

.carousel-image {
  width: 100%;
  height: 200px;
  margin: auto;
  padding: 10px 20px;
}

.card-container {
  border-radius: 4px;
  padding: 10px 5px;
}

.card {
  height: 360px;
  margin: 0 10px;
  padding-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #edf1f5;
  background-color: #ffffff;
}

.title-text {
  margin: 15px;
  padding: 0;
  font-size: 15px;
}

.price-text {
  margin: 0 10px 10px 10px;
  padding: 0;
}

@media (min-width: 320px) and (max-width: 576px) {
  .carousel-image {
    width: auto !important;
    height: 200px;
    margin: auto;
  }

  .customized-panel {
    margin-top: 0px;
    width: 100%;
  }

  .carousel-header {
    font-size: 20px;
    padding: 20px;
    font-weight: bold;
    margin: 0;
    line-height: 0;
  }

  .title-text {
    margin: 15px;
    padding: 0;
    font-size: 14px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .carousel-image {
    width: auto !important;
    height: 200px;
    margin: auto;
  }

  .title-text {
    margin: 15px;
    padding: 0;
    font-size: 14px;
  }
}

/* iPads (landscape) ----------- */
@media (min-width: 768px) and (max-width: 991px) {
  .carousel-image {
    width: auto !important;
    height: 200px;
    margin: auto;
  }
}

@media (min-width: 1200px) {
  .carousel-image {
    width: auto !important;
    height: 200px;
    margin: auto;
  }
}

/*End Custom Carousel*/

.shapeborder {
  border: 2px solid black;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
}

.circle {
  border-radius: 50%;
}

.outer {
  width: 50px;
  height: 50px;
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.inner {
  top: 4%;
  left: 4%;
  width: 44px;
  height: 44px;
  position: absolute;
  border: 1px solid #ced4da;
}

.inner:hover {
  cursor: pointer;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
}

/*No Outline Select CSS*/
.no-outline-select {
  padding-left: 15px;
}
.no-outline-select:focus {
  border: none;
  background: none;
}

.MuiBox-root > div {
  display: inline-block !important;
}

/*End No Outline Select CSS*/

/* React Pagination Start*/
.custom-pagination {
  display: inline-block;
}

.custom-pagination ul {
  display: inline-block;
}

.custom-pagination ul li {
  display: inline-block;
  padding: 0 8px;
}

.custom-pagination ul li a:focus {
  outline: 0;
  border: none;
}

.custom-pagination ul li.active {
  text-decoration: underline;
}

.custom-pagination ul li a {
  color: #939696;
  font-size: 12px;
  cursor: pointer;
}

.custom-pagination ul li .previousImg {
  display: inline-block;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #273142;
  position: relative;
  top: 3px;
  margin-right: 10px;
}

.custom-pagination ul li .nextImg {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #273142;
  position: relative;
  top: 3px;
  margin-left: 10px;
}

/* React Pagination end*/

/* Custom Cards start*/
.custom-card {
  height: 390px;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #edf1f5;
  background-color: #ffffff;
  margin: 0;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.overlay-list {
  position: absolute;
  /* top: 0; */
  /* left: 0; */
  width: 75%;
  height: 154px;
  /* height: 100%; */
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.custom-card:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.6);
}

.button {
  position: absolute;
  width: 100%;
  left: 0;
  top: 180px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
}

.button a {
  width: 200px;
  padding: 12px 48px;
  text-align: center;
  color: white;
  border: solid 2px white;
  z-index: 1;
}

.button:hover a {
  color: white;
}

.button-list {
  position: absolute;
  width: 75%;
  justify-content: safe;
  margin-top: 70px;
  opacity: 0;
  transition: opacity 0.35s ease;
}

.button-list a {
  width: 200px;
  padding: 12px 48px;
  text-align: center;
  color: white;
  border: solid 2px white;
  z-index: 1;
}

.button-list:hover a {
  color: white;
}

.custom-card-list:hover .overlay-list {
  display: block;
  background: rgba(0, 0, 0, 0.6);
}

.custom-card:hover .button {
  opacity: 1;
}

.custom-card-list:hover .button-list {
  opacity: 1;
}

.custom-card-list {
  height: 154px;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #edf1f5;
  background-color: #ffffff;
  margin: 5px;
  display: flex;
}

.card-item-name-list {
  padding: 20px 30px 20px 30px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.76px;
  text-align: left;
  color: #3f4143;
  height: 40px;
}

.list-bottom-line {
  display: flex;
  padding: 10px;
  /* text-align: left; */
}

.align-list {
  text-align: left;
  padding-left: 20px;
}

.card-item-price-list {
  font-size: 20px;
  text-align: center;
  color: #3f4143;
  padding: 5px 10px 0px 0px;
  margin-top: 5px;
}

.card-image-list img {
  padding: 10px 30px 10px 30px;
  height: 145px;
  width: 100%;
  border-radius: 4px;
}

.card-image {
  padding: 20px 30px;
  height: 170px;
}

.card-image img {
  width: auto;
  position: relative;
  border-radius: 4px;
}

.card-item-name {
  padding: 20px 30px 20px 30px;
  height: 100px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.76px;
  text-align: center;
  color: #3f4143;
}

.card-item-price {
  font-size: 20px;
  text-align: center;
  color: #3f4143;
  padding: 5px 10px;
  margin-top: 5px;
}

.quantity-stepper-box {
  margin-top: 20px;
}

.quantity-stepper-box p {
  margin: 0;
  padding: 0;
}

.product-quantity {
  color: #3f4243;
  display: inline-block;
}

.quantity-stepper1 {
  width: 135px;
  height: 30px;
  margin: auto;
  border-radius: 4px;
  border: solid 1px #dfe3e9;
  background-color: #ffffff;
  display: flex;
}

.quantity-field1 {
  width: 69px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  color: #5b5e5f;
  height: 29px;
  border: solid 1px #b0b1b2;
  padding-left: 0;
}

.minus,
.plus {
  width: 32px;
  height: 29px;
  border-radius: 4px;
  border: solid 1px #b0b1b2;
  background-color: #ffffff;
  cursor: pointer;
  padding-left: 0;
}

.minus {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.plus {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

@media (min-width: 576px) and (max-width: 767px) {
  .quantity-stepper1 {
    margin-top: 31px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .custom-card {
    height: 350px;
    margin: 5px;
  }

  .card-image {
    padding: 20px 35px 10px 35px;
    height: 175px;
  }

  .card-image img {
    width: auto;
    border-radius: 4px;
    height: 145px;
  }

  .card-item-name {
    padding: 10px 30px;
    height: 65px;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.76px;
    text-align: center;
    color: #3f4143;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card-image {
    padding: 20px 35px 10px 35px;
    height: 175px;
  }

  .card-image img {
    width: auto;
    border-radius: 4px;
    height: 145px;
  }

  .card-item-name {
    padding: 10px 30px;
    height: 65px;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.76px;
    text-align: center;
    color: #3f4143;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
  .custom-card {
    height: 390px;
    margin: 0 10px 15px 0;
  }

  .card-image {
    padding: 20px 35px 15px 35px;
    height: 210px;
  }

  .card-image img {
    width: auto;
    border-radius: 4px;
    height: 175px;
  }

  .card-item-name {
    padding: 10px 30px;
    height: 65px;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.76px;
    text-align: center;
    color: #3f4143;
  }

  .quantity-stepper1 {
    margin-top: 23px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
  .custom-card {
    height: 390px;
    margin: 0 10px 15px 0;
  }

  .card-item-name {
    padding: 20px 30px 20px 30px;
    height: 80px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.76px;
    text-align: center;
    color: #3f4143;
  }
}

@media (min-width: 1024px) {
  .custom-card {
    height: 390px;
    margin: 0 10px 15px 0;
  }

  .card-image {
    padding: 20px 40px 10px 40px;
    height: 205px;
  }

  .card-item-name {
    padding: 20px 35px 20px 35px;
    height: 80px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.76px;
    text-align: center;
    color: #3f4143;
  }

  .card-button {
    padding: 5px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .custom-card {
    height: 390px;
    margin: 5px;
  }

  .card-image {
    padding: 20px 30px 10px 30px;
    height: 175px;
  }

  .card-image img {
    width: auto;
    border-radius: 4px;
    height: 145px;
  }

  .card-item-name {
    padding: 20px 30px 20px 30px;
    height: 100px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.76px;
    text-align: center;
    color: #3f4143;
  }
}

/* Custom Cards end*/
/* Custome Page Loader */
#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: none;
}

#cover-modal {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(134, 127, 127, 0.3);
  z-index: 999;
  display: none;
  overflow: auto;
}

/* #cover-modal .product-dimensions {
  max-height: 100px;
  overflow-y: scroll;
} */

#cover-modal .color-swatch-box {
  overflow-y: auto;
  max-height: 100px;
}

.close-modal {
  float: right;
  margin: 40px 40px 0px 0px;
  cursor: pointer;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  height: 50%;
  width: 70%;
}

.modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
}

.modal-body .product-details,
.product-feature {
  margin-top: 0px;
}

.modal-title {
  font-size: large;
  font-weight: 600;
  padding: 0px 20px 20px 0px;
  /* padding-left: 0px; */
}

.modal-data {
  display: flex;
}

.modal-img-container {
  text-align: center;
  width: 40%;
}

.modal-description {
  text-align: center;
  width: 60%;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: #e41e26;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.quick-searchcontainer {
  padding: 5px;
}

.product-quantity1 {
  color: #3f4243;
  display: inline-block;
  padding-right: 20px;
  font-size: medium;
  margin-top: 33px;
}

.inventoryLeft {
  display: block;
}

.display-list {
  margin-left: 10px;
  /* word-wrap: break-word; */
  height: auto;
  /* white-space: normal; */
  /* width: 100%; */
  padding-bottom: 10px;
  padding-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activeList {
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activeList,
.display-list:hover {
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, #edf1f5, #edf1f5);
  cursor: pointer;
  font-size: 14px;
}

.quick-searchcontainer {
  padding: 5px;
}
.quick-searchcontainer {
  padding: 5px;
}

/* order and quote list mobile layout  */

.listHeaders {
  font-weight: bold;
  padding: 11px;
}

.centerTextCat {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

.outerBorder {
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

.gallery img {
  width: 100%;
  height: auto;
  /* max-height: 187px; */
}

.gallery a[href*='Surface Adhesive'] img {
  width: auto;
  max-height: 187px;
}

.gallery a[href*='Powder'] img {
  width: auto;
  max-height: 187px;
}

.desc {
  padding: 15px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5b5e5f;
}

* {
  box-sizing: border-box;
}

.responsive {
  padding: 0 6px;
  float: left;
  width: 20%;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.catContainer {
  text-align: center;
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding-top: 15px;
  padding-bottom: 15px;
}

.containerCategory {
  width: 100%;
  margin-top: 33px;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
}

.viewText {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #5b5e5f !important;
  text-transform: capitalize !important;
}

.view {
  width: 100%;
  text-align: center;
}

.staticbanner {
  /* background-image: url('https://scx1.b-cdn.net/csz/news/800/2019/2-nature.jpg'); */
  /* background-image: url('./img/banner.jpg'); */
  background-color: #cccccc;
  height: 356px;
  background-size: cover;
  background-position-y: center;
  color: #ffffff;
}

/* .staticbanner.tigerdirect { */
/* background-position-x: center;
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-color: #066d68; */
/* } */

.staticbanner.ventec {
  /* background-image: url('./img/ventec-banner.jpg'); */
}

.staticbanner.ppg {
  /* background-image: url('./img/ppg-dashboard-image.jpg'); */
}

.dashboard-description > div {
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
}

.desktopCards {
  z-index: 999999;
  margin-top: -120px !important;
}
.bannerTexttablet {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.vAlign {
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}
.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.Aligner-item--center {
  height: 33%;
}

.Aligner-item--top {
  height: 33%;
}

.Aligner-item--bottom {
  height: 30%;
  padding-top: 50px;
}
.cardNumbers {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #232323;
}
.cardMainText {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.2px;
  color: #232323;
}
.cardActionLink {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: #007bff;
}
.bannerWelcomeText {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  background: rgba(0, 0, 0, 0.7);
  padding: 2px 10px;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #f2f2f2;
}
.bannerSubText {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 2px;
  color: #f2f2f2;
}
.bannerSubTextValue {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #b2b2b2;
}
.announcementSectionTitle {
  height: 15%;
  padding-top: 15px;
  font-size: 20px;
}
.announcementSectionMainText {
  height: 70%;
  padding: 30px 40px;
  line-height: 1;
  font-size: 36px;
}
.announcementSectionAction {
  height: 10%;
  font-size: 20px;
  padding-top: 50px;
  padding-bottom: 10px;
}
.notificationTitle {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 2px;
  color: #f2f2f2;
}
.notificationSubText {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: #ffffff;
}
.notifyTitle {
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.29px;
  color: #ffffff;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.notifyMsg {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.46px;
  color: #ffffff;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}
.notifyDataHeading {
  font-size: 8px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  color: #666666;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.notifyDataHeadingValue {
  font-size: 10px;
  padding: 0 10px;
}
.notifyAction {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  color: #007bff;
}
.notificationTitleSection {
  /* height: 60px; */
  margin-bottom: 10px;
}
.notifySection {
  height: 40px;
  background-color: #111111;
  padding: 0px;
}
.notifyMainSection {
  /* height: 220px; */
  background-color: #232323;
}
.moreSection {
  height: 30px;
  padding-top: 10px;
  text-align: center;
}
@media only screen and (max-width: 800px) {
  .desktopCards {
    margin-top: 30px !important;
  }
  .staticbanner {
    height: 200px;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 960px) {
  /* STYLES GO HERE */
  /* added for dashboard banner  */
  .bannerWidth {
    max-width: 100% !important;
    flex-basis: 100% !important;
    /* flex-wrap: wrap; */
  }
}
@media only screen and (min-width: 1024) {
  /* STYLES GO HERE */
  /* added for dashboard banner  */
  .bannerWidth {
    max-width: 100% !important;
    flex-basis: 100% !important;
    /* flex-wrap: wrap; */
  }
}
@media only screen and (max-device-width: 1024px) and (min-device-width: 768px) {
  .latest-order-summary {
    display: block !important;
  }
}

.latest-orders {
  font-size: 16px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 2px;
  color: #232323;
  padding: 10px;
}

.common-style {
  border: solid 1px #cccccc;
  background-color: #ffffff;
}

.common-margin {
  margin: 20px;
}
.rowText td {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /* line-height: 40px; */
  color: #000000 !important;
  /* width: 20% !important; */
  border-bottom: none;
}

.quickOrder {
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 50px;
  color: #111111;
}

.headdata td,
.summary-data {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px;
  color: #232323;
}

.latest-order-summary td {
  padding-top: 0 !important;
  padding-bottom: 0;
}

.latest-order-summary th.MuiTableCell-root.MuiTableCell-head {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiTableCell-root .MuiTableCell-head .dashboard-order {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  color: #666666;
}

.outerTable th,
.summary-head {
  font-style: normal;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 30px;
  color: #666666;
}

.outerTable {
  background: #f2f2f2;
}

.inner-head th,
.head-text-db {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #666666;
  /* width: 20% !important; */
}

.inner-head {
  background: #e9e9e9;
}

.outerTable .MuiTableCell-root {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.outerTable th:nth-child(6) {
  color: green !important;
}

.outerTable td:nth-child(7) {
  width: 15%;
  text-align: center;
}

.outerTable td:nth-child(2),
td:nth-child(3) td:nth-child(4) {
  width: 30%;
}

.outerTable td:nth-child(7) {
  padding: 0 !important;
}
.outerTable th:nth-child(7) {
  color: #007bff !important;
  text-align: center;
}
.latest-order-summary {
  background: #fafafa;
  padding-left: 15px;
}
.add-scroll {
  overflow-x: scroll !important;
}
.text-center {
  text-align: center;
}
.add-padding {
  padding: 10px;
}
.display-flx {
  display: flex;
  text-align: center;
}
.add-border {
  border: solid 1px #cccccc;
}
.add-background {
  background: #f2f2f2;
}
.color-text {
  color: green !important;
}
.color-link {
  color: #007bff !important;
}
.orderHeader {
  display: flex;
  justify-content: center;
}
.display-flex {
  display: flex;
}
.reset-margin {
  margin-bottom: 0;
}
.icon-style {
  text-align: center;
  font-size: medium;
}
.inner-table thead {
  display: inline-block;
  width: 100%;
}
.inner-table tbody {
  overflow: auto;
  display: inline-block;
  width: 100%;
  height: 139px;
}

.image-box {
  text-align: center;
  /* padding: 30px 20px; */
  margin: 30px;
  /* background: #d5d5d5c2; */
}

.slick-prev:before,
.slick-next:before {
  color: #d5d5d5 !important;
}

.slick-slider .slick-initialized {
  width: 400px;
}
.product-details-box {
  padding: 30px 50px 30px 50px;
}

.product-name {
  font-size: 18px !important;
  font-weight: bold;
  line-height: 1.13;
  color: #3f4243;
}

/* .product-name {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.13;
  color: #3f4243;
} */

.price-box {
  margin-top: 30px;
}

.product-price {
  font-size: 20px;
  color: #3f4243;
  display: inline-block;
  padding: 0;
}

.product-status {
  font-size: 14px;
  color: #439e4e;
  display: inline-block;
  margin-left: 5px;
  padding: 0;
}

.outofstock-color {
  color: #ff0000;
}

.product-dimensions {
  clear: both;
  font-size: 14px;
  color: #5b5e5f;
  margin-top: 20px;
}

.product-dimensions p {
  padding: 0;
  margin: 0;
  line-height: 1.5;
}

.quantity-stepper-box {
  margin-top: 20px;
}

.quantity-stepper-box p {
  margin: 0;
  padding: 0;
}

.quantity-stepper {
  margin-top: 10px;
  width: 163px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #dfe3e9;
  background-color: #ffffff;
}

.product-quantity {
  color: #3f4243;
}

.button-minus,
.button-plus {
  width: 37px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #b0b1b2;
  background-color: #ffffff;
  cursor: pointer;
  padding-left: 0;
}

.quantity-field {
  width: 87px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  color: #5b5e5f;
  height: 40px;
  border: solid 1px #b0b1b2;
  padding-left: 0;
}

.add-to-quote {
  margin: 15px 0 20px 0;
  height: 40px;
  position: relative;
  display: flex;
}
/* Media Queries */
/* Smartphones (portrait and landscape) ----------- */
@media (max-width: 575px) {
  .image-box img {
    height: auto !important;
    /* max-height: 400px; */
    width: 100% !important;
    margin: 0 auto;
  }

  .image-box iframe {
    height: 100% !important;
    width: 100% !important;
  }

  .image-box video {
    height: 100% !important;
    width: 100% !important;
  }

  .product-details-box {
    padding: 20px;
  }

  .product-name {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.19;
    color: #3f4243;
  }

  .product-price {
    font-size: 16px;
    color: #3f4243;
    padding: 0;
    margin-bottom: 0;
  }

  .price-box {
    margin-top: 20px;
  }

  .product-status {
    font-size: 12px;
    color: #439e4e;
    display: inline-block;
    margin-left: 5px;
    padding: 0;
  }

  .outofstock-color {
    color: #ff0000;
  }

  .product-dimensions {
    font-size: 12px;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (min-width: 576px) and (max-width: 767px) {
  .image-box img {
    height: 250px !important;
    width: auto !important;
    margin: 0 auto;
  }
  .image-box iframe {
    height: 250px !important;
    width: 100% !important;
  }

  .image-box video {
    height: 250px !important;
    width: 100% !important;
  }
  .product-details-box {
    padding: 20px;
  }

  .product-name {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.19;
    color: #3f4243;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 768px) and (max-width: 1024px) {
  .image-box img {
    height: auto !important;
    /* max-height: 400px; */
    width: 100% !important;
    margin: 0 auto;
  }

  .image-box iframe {
    height: 400px !important;
    /* width: 350px !important; */
    width: 100%;
  }

  .image-box video {
    height: 400px !important;
    /* width: 350px !important; */
    width: 100%;
  }

  .product-details-box {
    padding: 30px 20px 20px 20px;
  }

  .product-name {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.19;
    color: #3f4243;
  }

  .product-price {
    font-size: 20px;
    color: #3f4243;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .image-box img {
    height: auto !important;
    /* max-height: 400px; */
    width: 100% !important;
    margin: 0 auto;
  }

  .image-box iframe {
    height: 400px !important;
    /* width: auto !important; */
    width: 100% !important;
  }

  .image-box video {
    height: 100% !important;
    width: 100% !important;
  }

  .product-details-box {
    padding: 30px 50px 30px 50px;
  }

  .product-name {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.19;
    color: #3f4243;
  }

  .product-price {
    font-size: 20px;
    color: #3f4243;
  }
}

.success {
  color: #02711c;
  font-weight: bold;
  font-size: 14px;
  font-style: italic;
}

.error {
  color: #de0909;
  font-weight: bold;
  font-size: 14px;
  font-style: italic;
}

.error-message {
  color: #de0909;
  font-weight: bold;
  font-size: 14px;
}

.green {
  color: green;
  font-weight: bold;
  font-size: 14px;
}

.product-details,
.product-feature {
  border-radius: 4px;
  background-color: #ffffff;
  margin-top: 35px;
}

.product-feature {
  margin-top: 20px;
}

.text-align-left {
  text-align: left;
  margin: 0;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.1;
  letter-spacing: normal;
  color: #3f4143;
}

.logoContainer img {
  width: 140px;
}

.logoContainer.ppg img {
  width: auto;
  height: 40px;
}

.text-format {
  border-radius: 2px;
  border: solid 1px #dfe3e9;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 36px !important;
  background-color: #ffffff !important;
  box-sizing: border-box;
  width: 70%;
  padding: 2px;
}

.paper {
  margin-top: 25px;
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
}

/* .login-form {
    width: '100%';
    margin-top: 1px;
    text-align: 'center';

} */

.align-center {
  text-align: center;
}

.img-set {
  background-size: cover !important;
  background-color: #ffffff;
  height: 100vh !important;
  background-repeat: no-repeat !important;
}

/* .set-margin {
    padding-bottom: 33px;
    padding-top: 33px;
} */

.set-bottom {
  margin-top: 25px;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.text-style {
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #3f4243;
  padding-top: 40%;
}

/* #password,
#email {
    padding: 6px;
} */

html,
body {
  height: 100%;
  margin: 0;
}

.full-height {
  height: 100%;
  background: none;
}
#email,
#password {
  padding-left: 12px;
}
.mob-button {
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.32);
  background-color: rgba(216, 216, 216, 0);
  width: 25%;
  /* height: 40px; */
}
.mob-text {
  font-size: 14px !important;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
button {
  padding: 10px;
  font-size: 24px;
}

button:disabled {
  opacity: 0.5;
}
.circularLoader {
  /* width: 20%; */
  margin-left: 10px;
  /* display: inline-block; */
}
.forgotPasswordLink {
  color: #3f51b5;
}
.forgotPasswordLink:hover {
  text-decoration: underline;
}

.MuiTabs-flexContainer {
  background-color: #fafbfc;
}

.MuiTab-wrapper {
  font-weight: bold;
  text-transform: capitalize;
  /* border: 2px solid green; */
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  /* color: #939696; */
  font-weight: bold;
  color: #3f4243;
}

#contact-text {
  width: 80%;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #5b5e5f;
  padding-bottom: 20px;
}

#contact-text-mobile {
  width: 80%;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #5b5e5f;
}

/* .MuiButtonBase-root {
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #3f4243;
} */
table,
th,
td {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #5b5e5f !important;
}

.MuiTableCell-alignRight {
  text-align: left !important;
  flex-direction: initial;
}

.inner-wrap {
  padding-left: 33px;
  background-color: #fafbfc;
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  /* padding-top: 33px; */
}

.MuiTableSortLabel-root {
  flex-direction: initial;
}

.makeStyles-tableWrapper-101 {
  width: 100%;
}

.MuiBox-root > div {
  display: block !important;
}

.MuiTableCell-root
  MTableHeader-header-298
  MuiTableCell-head
  MuiTableCell-paddingCheckbox {
  display: none;
}

/* New changes */
#account-management-text {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 0.86px;
  color: #3f4143;
  padding-left: 33px;
  text-transform: uppercase;
}

.account-management {
  width: 100%;
  background-color: #eff3f6;
  height: 63px;
  line-height: 63px;
}

/* .tab-padding{
    padding: 3em;   
} */
.row-dimension {
  padding: 30px;
}

.MuiAppBar-colorPrimary {
  background-color: none;
}

.address-container {
  display: flex;
  justify-content: space-between;
  width: 98%;
  border-bottom: solid 1px #dfe3e9;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  width: 98%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #dfe3e9;
}

.cancel-contact-mobile {
  width: 98%;
  border-top: solid 1px #dfe3e9;
}

.search-container {
  padding-bottom: 20px;
}

.add-address-container {
  width: 98%;
  border: solid 1px #dfe3e9;
  border-radius: 5px;
  margin-top: 20px;
}

.addressButtons {
  float: right;
  padding: 20px;
  padding-right: 35px;
  cursor: pointer;
}

.addressDeleteButton {
  float: right;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

#text-input {
  width: 100%;
  height: 40px;
  border: solid 1px #dfe3e9;
  border-radius: 5px;
  padding: 5px;
}

#address {
  padding-top: 40px;
  padding-bottom: 40px;
  /* width: 100%; */
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #5b5e5f;
  /* border-bottom: solid 1px #dfe3e9;
    width: 98%; */
}

#addNewAddress {
  padding-top: 40px;
  padding-bottom: 40px;
  /* width: 100%; */
  height: 50px;
  line-height: 50px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #5b5e5f;
  display: flex;
  cursor: pointer;
  /* border-bottom: solid 1px #dfe3e9;
    width: 98%; */
}

#addContact {
  letter-spacing: normal;
  color: #5b5e5f;
  cursor: pointer;
}

.address-wrapper {
  margin-top: 40px;
  width: 100%;
  padding-left: 15px;
  /* margin-left: 15px; */
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding-bottom: 20px;
}

.MuiGrid-root.display-desktop {
  padding-left: 0px;
}

.address-head {
  width: 97%;
  padding: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  background-color: #e41e26;
  margin-top: 20px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff;
  display: flex;
}

.head-text {
  margin-left: 10px;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #3f4243;
  /* padding: 14px 0px 14px 0px; */
}

.address-card {
  width: 97%;
  height: 20em;
  border-radius: 4px;
  border: solid 1px #dfe3e9;
  padding-left: 20px;
  padding-top: 20px;
  background-color: #ffffff;
  padding-bottom: 10px;
}

.address-one {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.88;
  letter-spacing: normal;
  color: #5b5e5f;
}

.address-card > p {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  /* line-height: 1.5; */
  letter-spacing: normal;
  color: #5b5e5f;
}
.head-address {
  height: 21px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff;
  padding-left: 5px;
  width: 100%;
}

#company-information {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #5b5e5f;
  border-bottom: solid 1px #dfe3e9;
}

.company-wrapper {
  width: 100%;
  padding-left: 15px;
  /* margin-left: 15px; */
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding-bottom: 20px;
}

.circle-account {
  margin-top: 10px;
  width: 45px;
  height: 45px;
  border-radius: 50%;

  color: #fff;
  line-height: 45px;
  text-align: center;
  background-color: #3f4143;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

.company-card {
  background-color: #ffffff;
  padding-left: 3%;
  padding-top: 20px;
  padding-bottom: 10px;
  width: 100%;
}

#company-name {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #5b5e5f;
}

.company-card > p {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5b5e5f;
}

.company {
  padding-top: 20px;
}

/* .head-text{
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #3f4243;
  } */
.inner-wrap > table > thead > tr {
  font-size: 10px;
  font-weight: bold !important;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #3f4243;
}

tr#contactForm > td {
  border-bottom: rgb(236, 234, 233);
  padding-left: 0px;
}

#contactForm .select-input {
  margin-top: 20px;
}

tr#buttonSave > td {
  /* padding: 30px; */
  margin: auto;
}
tr#tableCenterData > td {
  margin: auto;
}
tr#buttonSave
  .MuiCircularProgress-root.MuiCircularProgress-colorSecondary.MuiCircularProgress-indeterminate {
  display: inline-block;
  padding: 5px;
}

.tab-half {
  background-color: #fafbfc;
}

.display-desktop {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}

.address-root {
  flex-grow: 1;
}

.display-address {
  padding-top: 15px;
}
.search {
  border-radius: 4px;
  margin-top: 25px;
  border: solid 1px #dfe3e9;
  height: 41px;
  display: flex;
}
.MuiTableCell-alignRight {
  text-transform: capitalize;
}
.text-bold {
  font-size: 10px !important;
  font-weight: bold !important;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6 !important;
  letter-spacing: normal !important;
  color: #3f4243 !important;
  padding: 14px 0px 14px 0px;
}
#search {
  width: 40%;
  margin-top: 25px;
  display: flex;
}
/* .MuiTableCell-body{
    font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5b5e5f;

} */
.set-padding {
  padding: 14px 8px 14px 8px !important;
}

.add-address-container
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
  font-size: medium;
  font-weight: bold;
  color: #5b5e5f;
}

.add-new-contact-container
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
  font-size: medium;
  font-weight: bold;
  color: #5b5e5f;
}
.addressSelect
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiInputLabel-shrink {
  font-size: medium;
  font-weight: bold;
  color: #5b5e5f;
}
.add-address-container span.MuiIconButton-label {
  font: icon;
}

.add-address-container span.MuiButton-label {
  text-transform: none;
  font-weight: bold;
}

span.filter-option-label {
  font-size: 13px;
  font-weight: bold;
  color: #5b5e5f;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.addressSelect .MuiFormControl-root {
  width: 100%;
}

.MuiGrid-grid-xs-12 .addressSelect {
  margin-left: 8px;
  width: 100%;
}

.account-button {
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.32);
  background-color: rgba(216, 216, 216, 0);
  /* width: 10%; */
  margin-right: 20px;
  margin: 8px;
}
.required label:after {
  color: #e32;
  content: ' *';
  display: inline;
  font-size: 20px;
}

.MuiBox-root-251,
#accounts .MuiBox-root {
  padding: 0 !important;
}

.tab-padding .display-desktop {
  padding-left: 0px;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4.MuiGrid-grid-md-4.MuiGrid-grid-lg-4 {
  margin-right: 10px;
}
.tooltipFont {
  font-size: 12px;
  align-self: center;
}
.editIcon {
  cursor: pointer;
  color: black;
}
.toopTip {
  align-self: center;
  margin-top: 20px;
  margin-left: 20px;
}
.registrationForm {
  display: flex;
  flex-wrap: wrap;
}
.mobPadding {
  padding-left: 25px;
}
.buttonClass {
  margin-left: 10;
  margin-bottom: 10;
  width: 180;
  height: 41;
}

#accounts
  .MuiPaper-root.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-positionStatic.tab-padding.MuiAppBar-colorPrimary {
  z-index: 0;
}
.marginL {
  text-transform: capitalize !important;
  background-color: transparent;
  border: none !important;
  font-size: 0.875rem !important;
}
.marginL:hover {
  text-decoration: none !important;
  background-color: rgba(0, 0, 0, 0.08);
}
.buttonContainer {
  margin-left: 5px !important;
  padding-bottom: 15px;
  padding-top: 5px;
  display: flex;
}
.buttonContainer :focus {
  outline: none;
}

.search {
  /* height: 38px; */
  width: 100%;
  border-radius: 4px;
  border: solid 1px #dfe3e9;
}

button.search-icon {
  height: 39px;
  width: 41px;
  padding: 0px;
}

.search-input {
  border: none;
  width: 100%;
  height: 39px;
  padding: 0px;
}

.order-summary-container {
  background-color: #eff3f6;
  padding: 0 0 16px;
}
.order-summary-container td,
.order-summary-container th {
  border-bottom: none;
}
.order-summary-container .order-summary-tbody {
  padding: 10px 0;
}
.order-summary-container .order-summary-tbody tr:nth-child(n) td {
  padding: 5px 16px;
}
.order-summary-container
  .order-summary-tbody
  tr:nth-child(n)
  td:first-child {
  font-weight: bold;
}
.order-summary-container
  .order-summary-tbody
  tr:nth-child(n)
  td:last-child {
  text-align: right;
}
.order-summary-container
  .order-summary-tbody
  tr:nth-child(n)
  td:last-child:before {
  content: '$';
}
.order-summary-container
  .order-summary-tfooter
  tr:nth-child(1)
  td:first-child {
  font-weight: bold;
}
.order-summary-container
  .order-summary-tfooter
  tr:nth-child(1)
  td:last-child {
  text-align: right;
  font-weight: bold;
}
.order-summary-container
  .order-summary-tfooter
  tr:nth-child(1)
  td:last-child:before {
  content: '$';
}
.order-summary-container .divider {
  padding: 0 16px;
}
.order-summary-container .placeorder-btn {
  background-color: #273142;
  color: white;
  font-weight: bold;
}
.order-summary-container .placeorder-btn:hover {
  color: black;
}
/* Added for email cart */
.email-cart {
  border-radius: 2px;
  border: solid 1px #dfe3e9;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 36px !important;
  background-color: #ffffff !important;
  box-sizing: border-box;
  width: 100%;
  padding: 2px;
}

.display-property{  
  display: flex;
  justify-content: center;
}
.align-div{
  text-align: center;
}

.productTitle {
  text-align: left !important;
  padding-left: 15px;
}

.order-cart-checkout {
  max-height: 778px;
  overflow: auto;
}

.order-cart-checkout .table .tbody td:first-child {
  width: 60%;
}

.order-cart-checkout .table .tbody td:last-child {
  padding: 0px 16px 0;
}

.order-cart-checkout .table .tbody .text-center {
  text-align: center;
  top: 0;
  padding: 0;
  margin: 0;
}

.order-cart-checkout .table .tbody .text-center input {
  text-align: center;
}

.order-cart-checkout .quantity-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.quantity-wrapper input {
  text-align: center;
}
/* added for checkout quantity */
.quantity-wrapper span {
  margin: auto;
}

.quantity-wrapper .Mui-disabled::before {
  border: none;
  border-bottom-style: none !important;
}

.item-qty-checkout {
  color: rgba(0, 0, 0, 0.38);
}

.order-cart-checkout .table .tbody .price-wrapper .edit-remove-btn {
  position: absolute;
  margin-top: 15px;
}

.order-cart-checkout .price:before {
  content: '$';
}

.text-uppercase {
  text-transform: uppercase;
}

.wrapper {
  display: grid;
  grid-template-columns: 5fr 1fr 1.2fr 1fr;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 16px;
  cursor: pointer;
}

.item-wrapper {
  height: 100%;
}

.item-header {
  font-weight: bold;
  color: #5b5e5f;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1.3125rem;
  /* remove to keep the text in center at checkout & cart page */
  /* padding: 14px 40px 0px 16px;  */
  padding-top: 14px;
  text-align: center;
}

.item-content {
  padding: 14px 14px 14px 16px;
  font-size: 0.875rem;
}

.item-content:last-child {
  padding: 0 16px;
}

.item-content.qty {
  /* padding-right: 0px; */
  padding: 0px;
  width: 100%;
}

.box-cell:nth-child(5) {
  grid-column-start: 4;
}

a {
  cursor: pointer;
}

@media (max-width: 768px) {
  .wrapper {
    grid-template-columns: repeat(3, 1fr);
  }

  .box-cell:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .box-cell:nth-child(5) {
    grid-column-start: 2;
    grid-column-end: 4;
    text-align: right;
  }

  .product-image {
    width: 34% !important;
  }
}

.container-print .table-cell {
  border: none;
  padding: 4px 8px 4px 8px;
}
.container-print {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 50px 0 0 0px;
}
.inner-container-print {
  border: 1px solid rgb(224, 224, 224);
 padding: 4px 8px 4px 8px;
}
.outer-container-print {
  padding: 15px;
}
.marginBottom {
  margin-bottom: 15px;
}
.logoPadding {
  padding: 10px 0px 10px 0px;
}
.borderBottom {
  border-bottom: 1px solid;
}

.marginFromTop {
  top: 10px;
}

.dividerClass {
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: #0000001f;
}

.highLightElement {
  border: 2px solid #ff0000;
}
.main-panel {
  margin: inherit;
}

.checkoutContainer {
  padding: 0 20px;
}

.cartWrapper .paddedContent {
  padding: 0 0px 15px 0 !important;
}
.paddedContent {
  width: 100%;
}

.orderHeading {
  padding: 16px 16px 0;
}

.product-image {
  width: 25%;
  float: left;
  margin-right: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.product-image img {
  width: auto;
  height: 80px;
  margin: 0 auto;
  display: block;
}

.product-name-checkout {
  font-weight: bold;
  font-weight: 16px;
  color: black;
}

.product-info {
  line-height: 2;
}

.thead th {
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}

.thead .MuiTableCell-root {
  border-bottom: none;
  padding-bottom: 0;
}

.alert-text-container {
  width: 100%;
}

.alert-text {
  font: message-box;
}

.bepsy-alert-button {
  padding: 40px 5px 0px;
}

#cart .modal-content {
  height: 150px;
  width: 350px;
}

#cart .modal-body {
  padding: 20px;
}
.flexDisplay {
  display: flex;
  justify-content: space-between;
}

body {
  margin: 0;
  padding: 0;
  flex-wrap: unset;
  background-color: #fafbfc;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.centered-text {
  font-size: 18px;
  font-weight: bold;
  margin: 20px auto;
  text-align: center;
}

div {
  flex-wrap: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.css-1ed3p0v {
  z-index: 99999;
}
/* copy right text below footer */
.copyRight {
  text-align: center;
  color: #939696;
}

/* External container for component - shadow(material) */
.externalContainer {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.childComponentSpacing {
  padding-left: 5em;
  padding-right: 5em;
}

/* Header */
.header_bg {
  background: #fff;
  color: #222;
}
/* Added for global input field */
input {
  padding-left: 12px;
}

/* Media Queries */
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* STYLES GO HERE */
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }

  .imageResizePromotion {
    height: 325px;
    background-size: cover;
  }

  .carousel {
    height: 325px;
  }

  .centeredPromotion,
  .centeredPromotion p {
    color: #3f4143;
    text-align: left;
    width: 100%;
    padding-left: 0;
    left: 0;
  }

  .centeredPromotion {
    margin-bottom: 33px;
  }

  .setWidthPromotion {
    height: 325px;
    text-align: center;
    /* top: 50%; */
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }

  .paraText {
    color: #3f4143;
  }

  /* Customer support */
  /* STYLES GO HERE */
  .imageResizeCustomer {
    height: 325px;
    background-size: cover;
  }

  .carousel {
    height: 325px;
  }

  .centeredCustomer,
  .centeredCustomer p {
    color: #3f4143;
    text-align: left;
    width: 100%;
    padding-left: 0;
    left: 0;
  }

  .centeredCustomer {
    margin-bottom: 33px;
    background-color: #eff3f6;
  }

  .setWidthCustomer {
    height: 325px;
    text-align: center;
    /* top: 50%; */
  }

  .paraText {
    color: #3f4143;
  }

  .textCenter {
    margin-top: 0;
  }
  /* Added for promotion */
  .add-margin {
    margin-bottom: 33px;
  }
  .btnClickPromotion {
    padding-bottom: 20px;
  }
  /* Added for recommended products */
  .center-text {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 14px !important;
  }

  /* Added for featured categories */
  .responsive {
    width: 50%;
  }

  .view {
    display: inline-block;
  }

  .react-multi-carousel-list > ul > li:first-child {
    padding-left: 20px;
    flex: auto;
  }

  .react-multi-carousel-list > ul > li:nth-child(odd),
  .react-multi-carousel-list > ul > li:nth-child(even) {
    flex: 1 1 !important;
  }

  /* Added for login */
  .mob-button {
    width: 100%;
    margin-left: 0;
  }

  .text-format {
    width: 100%;
  }

  .login-form {
    padding: 30px;
  }

  .text-style {
    padding-top: 20%;
  }

  .set-border-bottom {
    padding-bottom: 200px;
  }

  /* Added for account */
  /* Added for account */
  /* .display-desktop{
            display: block;
        } */
  .account-management {
    text-align: center;
  }

  .tab-half {
    width: 50%;
  }

  #search {
    padding-bottom: 20px;
  }

  .border-set {
    border-bottom: solid 1px #dfe3e9;
    margin-top: 20px;
    padding: 10px;
  }

  .mobile-profile {
    border-radius: 4px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .search {
    width: 100%;
  }
  .set-height-rec {
    /* margin-top: 25px; */
  }
  /* Added for mini cart */
  .miniCart-position {
    width: 100%;
    left: 0;
  }
  .miniCart-layout {
    width: 100%;
  }
  .mob-padding {
    padding-left: 1em;
    padding-right: 1em;
  }
  /* Added for confirmation page  */
  .footer-icons {
    text-align: center;
  }
  /* Added for Home Hero Banner */
  .imageResize {
    height: 325px;
    background-size: cover;
  }

  .carousel {
    height: 325px;
  }

  .centered {
    text-align: center;
    width: 100%;
    padding-left: 0;
    left: 0;
  }

  .setWidthCarousel {
    height: 325px;
    text-align: center;
    /* top: 50%; */
  }
  /* Added for Update Profile */
  .account-button {
    width: 25%;
    /* background-color: rgb(228, 30, 38); */
    color: rgb(255, 255, 255);
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    padding: 5px;
  }
  /* Added for Registration */
  .regFields {
    display: block !important;
    padding: 0 !important;
  }
  /* .formPadding {
    padding: 0 !important;
  } */

  .saveButton {
    display: block !important;
    padding: 0 !important;
    margin-top: 20px !important;
  }
  .textReg {
    text-align: center !important;
  }
  .buttonClass {
    margin-bottom: 20px !important;
  }
  .MuiGrid-grid-xs-12 .regAddressSelect {
    margin-left: 9px !important;
    width: 103% !important;
  }
  .verticalcenterDiv {
    left: 0;
  }
  .footerMargin {
    margin-top: 100%;
    padding-top: 70%;
  }
  .view-details-btn-mob {
    flex-direction: column !important;
  }
  .view-details-btn {
    margin: 10px 0px !important;
    padding: 5px !important;
  }

  /* Added for order history page */
  .pagination-row {
    padding: 10px !important;
  }
  /* Added for first time login */
  .mobPadding {
    text-align: left !important;
  }
  /* Added for order history */
  .orderSearchPage {
    display: block !important;
    width: 100% !important;
    flex-wrap: wrap;
  }

  .selectDrop {
    width: 100%;
    /* margin-bottom: 15px; */
  }
  .selectOrder {
    padding: 0 !important;
    margin-bottom: 10px !important;
  }
  .page-title {
    text-transform: uppercase;
    line-height: 45px;
    font-weight: bold;
  }
  /* Added for Order filter */
  .labelCss {
    display: none;
  }
  /* Added for account contact search for mobile*/
  .search-container {
    display: flex;
  }
  .contactSearch {
    height: 50px;
    padding: 10px;
  }
  /* Added for Need us (contact) Page */
  .contactFields {
    display: block;
  }
  /* Added for maintenance page */
  .img-maintenance-set {
    background-size: contain !important;
    background-color: #ffffff;
    height: 100vh !important;
    background-repeat: no-repeat !important;
  }
  /* Added for dashboard latest orders */
  .latest-order-summary {
    display: flex;
    justify-content: space-around;
  }
  .common-margin {
    overflow-x: scroll;
  }
  /* Added for dashboard */
  .latest-orders {
    text-align: center;
  }
  .inner-head th {
    min-width: 205px;
  }
  .rowText td {
    min-width: 205px;
  }
  .addscrol {
    overflow-x: scroll;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* STYLES GO HERE */
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }
  /* STYLES GO HERE */
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* STYLES GO HERE */
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }

  .centeredPromotion {
    text-align: left;
    align-items: center;
    width: 100%;
    padding-left: 5%;
    margin-bottom: 33px;
    /* padding-right: 0; */
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }
  .centeredPromotion p {
    text-align: left;
  }

  /* customer support */
  /* STYLES GO HERE */
  .centeredCustomer {
    text-align: left;
    align-items: center;
    width: 100%;
    padding-left: 5%;
    margin-bottom: 33px;
    /* padding-right: 0; */
    height: 430px;
    background: black;
    opacity: 0.7;
    top: 0;
    left: 0;
    padding-left: 0;
  }

  .centeredCustomer p {
    text-align: left;
  }

  .textCenter {
    top: 40%;
    text-align: left;
    margin-top: 25%;
    padding-left: 5%;
  }

  .paraText {
    text-align: left;
  }

  /* Added for recommended products */
  .center-text {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 14px;
  }

  /* Added for login */
  .text-style {
    padding-top: 20%;
  }

  .set-border-bottom {
    padding-bottom: 200px;
  }

  /* Added for account */
  .company-wrapper {
    margin-left: 0;
  }

  .display-desktop {
    display: flex;
  }
  /* Added for Home Hero Banner */
  .centered {
    text-align: center;
    align-items: center;
    width: 100%;
    padding-left: 0;
    /* padding-right: 0; */
    left: 0;
  }
  /* Added for checkout page */
  .quantity-wrapper input {
    /* text-align: left; */
  }
  /* Added for Registration */
  .saveButton {
    display: block !important;
    padding: 0;

    text-align: left;
  }
  .textReg {
    text-align: center !important;
  }
  .buttonClass {
    margin-bottom: 20px !important;
  }
  /* .saveButton {
    display: block !important;
    padding: 0;
  }
  .formPadding {
    padding: 0;
  } */
  .verticalcenterDiv {
    left: 25%;
  }
  .footerMargin {
    margin-top: 100%;
    padding-top: 0;
  }
  /* Added for First time login */
  .mobPadding {
    text-align: left !important;
  }
  .page-title {
    text-transform: uppercase;
    line-height: 45px;
    font-weight: bold;
  }
  /* Added for order filter */
  .labelCss {
    display: none;
  }
  .selectDrop,
  .selectOrder,
  .orderSearchPage {
    width: 100%;
  }
  .selectOrder {
    padding: 0 !important;
    margin-bottom: 10px !important;
  }
  /* Added for Need us (contact) Page */
  .contactFields {
    display: block !important;
  }
  /* Added for maintenance page */
  .img-maintenance-set {
    background-size: contain !important;
    background-color: #ffffff;
    height: 100vh !important;
    background-repeat: no-repeat !important;
  }
  /* Added for dashboard latest orders */
  .latest-order-summary {
    display: flex;
    justify-content: space-around;
  }
  .common-margin {
    overflow-x: scroll;
  }
  .latest-orders {
    text-align: center;
  }

  .outerTable td:nth-child(2),
  td:nth-child(3) td:nth-child(4) {
    width: 0%;
  }

  .outerTable th:nth-child(1),
  .outerTable th:nth-child(5),
  .outerTable th:nth-child(6),
  .outerTable th:nth-child(7) {
    width: 25%;
  }
  .inner-head th {
    min-width: 275px;
  }

  .rowText td {
    min-width: 275px;
  }
  .add-border {
    border: none;
  }
  .addscrol {
    overflow-x: scroll;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }
  /* STYLES GO HERE */
  .centeredPromotion {
    position: absolute;
    color: #ffffff;
    align-items: left;
    width: 100%;
    padding-left: 5%;
    margin-bottom: 33px;
    /* padding-right: 0; */
  }

  .centeredPromotion p {
    text-align: left;
  }

  /* STYLES GO HERE */
  .centeredCustomer {
    position: absolute;
    color: #ffffff;
    align-items: left;
    width: 100%;
    padding-left: 5%;
    margin-bottom: 33px;
    /* padding-right: 0; */
    height: 430px;
    background: black;
    opacity: 0.7;
    top: 0;
    left: 0;
    padding-left: 0;
  }

  .centeredCustomer p {
    text-align: left;
  }

  .textCenter {
    top: 40%;
    text-align: left;
    margin-top: 25%;
    padding-left: 5%;
  }

  .paraText {
    text-align: left;
  }

  /* Added for recommended products */
  .center-text {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 14px;
  }

  /* Added for login */
  .text-style {
    padding-top: 20%;
  }

  .set-border-bottom {
    padding-bottom: 200px;
  }
  /* Added for account */
  #search {
    padding-right: 20px;
  }
  /* Added for Home Hero Banner */
  .centered {
    align-items: center;
    width: 100%;
    padding-left: 0;
    /* padding-right: 0; */
    left: 0;
  }
  /* Added for checkout page */
  .quantity-wrapper input {
    /* text-align: left; */
  }
  /* Added for maintenance page */
  .img-maintenance-set {
    background-size: contain !important;
    background-color: #ffffff;
    height: 100vh !important;
    background-repeat: no-repeat !important;
  }
  /* Added for dashboard */
  .latest-orders {
    text-align: center;
  }
  .add-border {
    border: none;
  }
  .outerTable th:nth-child(7) {
    padding-top: 16px !important;
  }

  .addscrol {
    overflow-x: scroll;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }
  /* STYLES GO HERE */
  .centeredPromotion {
    position: absolute;
    color: #ffffff;
    align-items: left;
    width: 100%;
    padding-left: 0;
    /* padding-right: 0; */
  }

  /* customer support */

  /* STYLES GO HERE */
  .centeredCustomer {
    position: absolute;
    color: #ffffff;
    align-items: left;
    width: 100%;
    padding-left: 0;
    /* padding-right: 0; */
    width: 50%;
    height: 430px;
    background: black;
    opacity: 0.7;
    top: 0;
    left: 0;
  }

  .textCenter {
    top: 40% !important;
    text-align: left !important;
    margin-top: 25% !important;
    padding-left: 5% !important;
  }

  /* Added for recommended products */
  .center-text {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 14px;
  }

  /* Added for checkout page */
  .quantity-wrapper input {
    /* text-align: left; */
  }
  /* Added for dashboard */
  .latest-orders {
    text-align: center;
  }
  .outerTable th:nth-child(7) {
    padding-top: 16px !important;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 800px) {
  /* STYLES GO HERE */
  .childComponentSpacing {
    padding-left: 5em;
    padding-right: 5em;
  }

  .centeredPromotion {
    position: absolute;
    color: #ffffff;
    top: 40%;
    left: 5%;
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }
  /* customer support */
  /* STYLES GO HERE */
  .centeredCustomer {
    position: absolute;
    color: #ffffff;
    width: 50%;
    height: 430px;
    background: black;
    opacity: 0.7;
    top: 0;
    left: 0;

    /* top: 40%;
          left:5%; */
  }

  /* Added for recommended products */
  .center-text {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 14px;
  }

  .react-multi-carousel-list > ul > li:first-child {
    /* padding-left: 20px; */
    flex: 1 1 !important;
  }

  .react-multi-carousel-list > .react-multi-carousel-track > li {
    margin: 5px;
  }

  /* Added for login */
  .align-center {
    text-align: left;
  }

  .text-style {
    text-align: left;
  }

  /* Added for account */
  .display-desktop {
    display: flex;
    padding-left: 15px;
  }
  .contactFields {
    display: flex;
  }
  /* added for maitenance page */
  .img-maintenance-set {
    background-size: cover !important;
    background-color: #ffffff;
    height: 100vh !important;
    background-repeat: no-repeat !important;
  }
  /* added for dashboard */
  .display-prop {
    display: flex;
  }
  .orderHeader {
    display: block;
    /* justify-content: center; */
  }
  /* .inner-head th,
  .rowText td {
    width:20%;
  } */
  .removeFlexWrap {
    flex-wrap: wrap !important;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* STYLES GO HERE */
  /* Added for Home Hero Banner */
  .centered {
    top: 40%;
    left: 5%;
  }
  /* added for dashboard */
  .display-prop {
    display: flex;
  }
  .orderHeader {
    display: block;
    /* justify-content: center; */
  }
  .inner-head th,
  .rowText td {
    width: 20%;
  }
  .removeFlexWrap {
    flex-wrap: nowrap !important;
  }
}
@media only screen and (min-width: 800px) {
  .inner-head th,
  .rowText td {
    width: 20%;
  }
}
@media only screen and (min-width: 1180px) {
  .inner-head th,
  .rowText td {
    width: 20%;
  }
}
/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* STYLES GO HERE */
  .childComponentSpacing {
    padding-left: 5em;
    padding-right: 5em;
  }
  /* added for dashboard */
  .display-prop {
    display: flex;
  }
  .orderHeader {
    display: block;
    /* justify-content: center; */
  }
  .inner-head th,
  .rowText td {
    width: 20%;
  }
  .removeFlexWrap {
    flex-wrap: nowrap !important;
  }
}

/* iPhone 5 (portrait &amp; landscape)----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  /* STYLES GO HERE */
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }

  /* customer support */

  .setWidthCustomer {
    height: 325px;
  }
}

/* iPhone 5 (landscape)----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) {
  /* STYLES GO HERE */
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }

  .setWidthPromotion {
    height: 325px;
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }
  /* Added for Home Hero Banner */
  .setWidthCarousel {
    height: 325px;
  }
}

/* iPhone 5 (portrait)----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) {
  .childComponentSpacing {
    padding-left: 1em;
    padding-right: 1em;
  }
  .confirmationPageLayout {
    margin-left: 1em;
    margin-right: 1em;
  }
  /* STYLES GO HERE */
}

/* style change for toast message */
.css-1bm1z97-ToastContainer {
  z-index: 99999;
  margin-top: 65px !important;
}

.css-h2fnfe {
  /* z-index: 99999; */
  margin-top: 65px !important;
}

.MuiList-root {
  max-height: 200px;
  /* width: auto; */
}

.addAddress {
  display: flex;
  padding-top: 10px;
  float: right;
  padding-right: 10px;
}

.addressText {
  padding: 2px 10px 0px 10px;
}

.main-panel {
  margin: inherit;
}

.checkoutContainer {
  padding: 0 20px 40px;
  background-color: white;
}

.checkoutContainer .paddedContent {
  padding: 0 35px 15px 0;
}

.orderHeading {
  padding: 16px 16px 0;
}

.product-image {
  width: 118px;
  float: left;
  margin-right: 20px;
  border-radius: 5px;
  overflow: hidden;
}

.product-image img {
  width: auto;
  height: 80px;
  margin: 0 auto;
  display: block;
}

.product-name-checkout {
  font-weight: bold;
  font-weight: 16px;
  color: black;
}

.product-info {
  line-height: 2;
}

.thead th {
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}

.thead .MuiTableCell-root {
  border-bottom: none;
  padding-bottom: 0;
}

.po-container {
  /* margin: 10px 20px; */
  margin: 0px 20px;
}

#padding {
  padding: 14px;
}
.continueShopping>a {
   margin: 0 20px;
    padding-right: 40px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #134c5d;
    text-decoration: underline;
  }
  .showmodal{
    padding:20px;
    text-align: center;
  }

@media (max-width: 768px) {
  .checkoutContainer .paddedContent {
    padding: 0;
  }
}

.product-listing-container {
  padding: 20px;
}

.listing-left-panel {
  width: 100%;
}
.listing-right-panel {
  width: 100%;
}

.listing-facet {
  flex: 0 0 330px;
  max-width: 330px;
  padding: 0;
}

.listing-header {
  width: 100%;
  padding: 10px 0 15px 0;
  position: relative;
}

.filter {
  font-size: 16px;
  font-weight: bold;
  color: #3f4243;
}

.clear-all {
  font-size: 14px;
  color: #5b5e5f;
  text-decoration: underline;
  cursor: pointer;
}

.divider {
  width: 100%;
  height: 1px;
  background: #eff3f6;
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
  margin: 0;
}

.sort-by {
  font-size: 12px;
  color: #5b5e5f;
}

.sort-by-select {
  position: absolute;
  top: 10px;
  left: 60px;
}

.results-per-page-select {
  position: absolute;
  top: -9px;
  left: 110px;
}

.filter-type-container {
  max-width: 100%;
  padding: 8px;
  margin-top: 20px;
  max-height: 200px;
  overflow-y: auto;
}

.filter-type-label {
  font-size: 14px;
  color: #3f4243;
  margin: 10px 0 0 0px;
  line-height: 1;
}

.filter-checkbox {
  width: 17px;
  height: 17px;
}

.heirarchical-option {
  margin: 10px 0;
}
.filter-type-option {
  margin: 15px 0;
  max-height: 20px;
}

.filter-option-label {
  width: 70%;
  position: relative;
  top: 1px;
  font-family: SourceSansPro;
  font-size: 14px;
  color: #5b5e5f;
  margin-left: 10px;
  display: inline-block;
}

.filter-option-count {
  position: relative;
  top: 1px;
  display: inline-block;
  width: 10%;
  text-align: right;
  font-family: SourceSansPro;
  font-size: 16px;
  color: #5b5e5f;
}

.listing-panel-container {
  margin-top: 15px;
}

.card-image-list {
  width: 100%;
  height: 100%;
}

.MuiTreeItem-label {
  font-family: SourceSansPro !important;
  font-size: 14px !important;
  color: #5b5e5f !important;
}

.no-data-found {
  font-size: 18px;
  font-style: italic;
  margin-top: 10px;
  padding-left: 20px;
  color: #fd5531;
}

.pagination-grid-list {
  width: 50%;
  text-align: right;
  display: inline-block;
}

.pagination-grid-list .custom-pagination {
  vertical-align: super;
}

.activeView {
  width: 75px;
  border: 1px solid #a0a5a8;
  height: 25px;
  background: #a0a5a8;
  display: inline-flex;
  cursor: pointer;
}
.activeView-span {
  padding-left: 20px;
}

.inActive {
  width: 75px;
  border: 1px solid #a0a5a8;
  height: 25px;
  display: inline-flex;
  cursor: pointer;
}
/* Medium devices (landscape tablets, 768px and up) */
@media (max-width: 575px) {
  .filter-type-container {
    max-width: 100%;
    padding: 0;
    margin-top: -18px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* .results-per-page-select {
    left: 32%;
  } */
}

.filter-label {
  position: relative;
  right: 50px;
}

.filter-icon {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: solid 1px #edf1f5;
  position: absolute;
  right: 10px;
  top: 15px;
}

div#mobileFilter {
  width: 100%;
}

#mobileFilter .filter-type-container {
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  margin-top: 0px;
}

#mobileFilter span.MuiIconButton-label {
  font: icon;
}

#ancestor .MuiTypography-colorSecondary {
  color: #f50057;
}

#parent .MuiTypography-colorSecondary {
  color: #f50057;
}

#pageLayout .MuiGrid-spacing-xs-5 > .MuiGrid-item {
  padding: 0px;
}

.bannerBG {
  width: 100%;
  height: 390px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.bannerText {
  position: absolute;
  text-align: left;
  top: 250px;
  color: #fff;
  font-size: large;
  /* padding: 10px 40px; */
  word-wrap: normal;
  padding-right: 50px;
  padding-left: 20px;
}

.product-listing {
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

.no-data-found {
  font-size: 18px;
  font-style: italic;
  margin-top: 10px;
  color: #fd5531;
}

.search-field-invoice {
  position: absolute;
  display: inline-block;
  text-align: right;
  right: 5em;
}
.mobileInvoiceLayout {
  padding: 1em;
  border-bottom: 1px solid #d5d5d5;
}
.customPadding {
  padding-bottom: 1em;
  margin: auto !important;
}

@media (min-width: 1200px) {
  .search-field-invoice {
    width: 40%;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .search-field-invoice {
    width: 50%;
    right: 1em !important;
  }
}

@media (max-width: 575px) {
  .search-field-invoice {
    width: 60%;
    margin-bottom: 10px;
    right: 1em !important;
  }
}

.search-field-invoice {
  position: absolute;
  display: inline-block;
  text-align: right;
  right: 5em;
}
.paymentStatus {
  float: right;
}
.customPadding {
  padding-bottom: 1em;
}
.alignRight {
  text-align: right !important;
}

.confirmationPageLayout {
  margin-left: 10%;
  margin-right: 10%;
}
.successMsg {
  padding: 2em;
  text-align: center;
  margin-bottom: 3em;
}
.footer-icons {
  color: #e41e26;
  padding-top: 20px;
  display: flex;
}
.footer-icons > button {
  color: #e41e26;
}
.footer-icons > a {
  padding-right: 20px;
  padding-left: 20px;
  color: #e41e26;
}
.footer-icons > a > p {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f4243;
  text-decoration: underline;
}
.bottom-links {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.bottom-links > a {
  padding-right: 40px;
  padding-left: 40px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #134c5d;
  text-decoration: underline;
}
.order-footer-data {
  padding-top: 30px;
}
.priceConfirmation {
  text-align: left !important;
}
.loaderMargin {
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 10%;
  margin-right: 10%;
}

.textReg {
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #3f4243;
  /* padding-top: 40%; */
}
.formPadding {
  padding: 30px;
}
.regFields {
  display: flex;
  justify-content: space-between;
  /* padding-top: 20px;
         padding-bottom: 20px; */
  /* padding-right: 20px; */
}
.saveButton {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.setMargin {
  margin-right: 10px;
  margin-left: 10px;
}
.buttonClass {
  margin-left: 10px;
  margin-bottom: 10px;
  width: 180 !important;
  height: 41 !important;
}
#billAddress {
  text-align: left;
  padding: 10px;
}
.regFields .makeStyles-margin-105 > label {
  padding-left: 4px;
}
.successFormPadding {
  padding: 30px;
}
.regFieldText
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiInputLabel-shrink {
  font-size: medium;
  font-weight: bold;
  color: #5b5e5f;
  text-align: left;
}
.MuiGrid-grid-xs-12 .regAddressSelect {
  margin-left: 15px;
  width: 100%;
}
.regAddressSelect .MuiFormControl-root {
  width: 100%;
}

.regFields
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4.MuiGrid-grid-md-4.MuiGrid-grid-lg-4 {
  margin-right: 2px;
}

.containerDiv {
  height: 100%;
}
.verticalcenterDiv {
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.emailText,
.resetEmailText {
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: #5b5e5f;
  padding: 20px 0px 18px 0px;
  margin-bottom: 0;
}

.emailContainer {
  display: inline-block;
}
.submitButton {
  width: 25%;
  display: block;
  height: 45px;
  font-size: 100%;
}
.emailLabel,
.emailInput {
  font-family: inherit;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.1;
  letter-spacing: normal;
  color: #3f4143;
}
.emailInput {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  color: rgb(0, 0, 0) !important;
  margin-top: 3px;
  margin-bottom: 40px;
}
.loginLink {
  padding-top: 50px;
}
.loginLink a {
  color: #5b5e5f;
  text-decoration: none;
  font-family: inherit;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.backToLogin {
  width: 46px;
  vertical-align: middle;
  height: 46px;
}
.logoContainer {
  margin-top: 50px;
}
.resendEmailContainer {
  padding-top: 19px !important;
}
.resendEmailLink {
  font-family: inherit !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  color: #237e9a !important;
}
.emailHeader {
  padding-top: 51%;
}
.linkLeftMargin {
  margin-left: 46px !important;
}
@media (max-width: 767px) {
  .loginLink > a:hover {
    text-decoration: none !important;
  }
  .emailHeader {
    padding-top: 100px !important;
  }
  .resendEmailContainer {
    padding-top: 22px !important;
    padding-bottom: 80px !important;
  }
  .resendEmailLink {
    text-decoration: none;
  }
  .text-style {
    padding-top: 66px;
  }
  .logoContainer {
    margin-top: 58px;
  }
  .set-border-bottom {
    padding-bottom: 32px;
  }
  .emailText,
  .resetEmailText {
    width: 100%;
    padding: 20px 30px 12px 30px;
    font-size: 16px;
    text-align: center;
    margin: auto;
  }
  .resetEmailText {
    width: 256px;
    padding: 21px 0px 12px 0px;
  }
  .emailContainer {
    text-align: left;
    width: 100%;
    margin: auto;
  }
  .emailInput {
    width: 100%;
  }
  .submitButton {
    width: 100px;
  }
  .login-form {
    padding: 0 30px 30px 30px;
  }
  .linkLeftMargin {
    margin-left: 40px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .emailHeader {
    padding-top: 200px !important;
  }
  .resendEmailContainer {
    padding-top: 16px !important;
    padding-bottom: 73px !important;
  }
  .text-style {
    padding-top: 136px;
  }
  .logoContainer {
    margin-top: 148px;
  }
  .set-border-bottom {
    padding-bottom: 232px;
  }

  .emailText,
  .resetEmailText {
    width: 335px;
    margin: auto;
    padding: 15px 0px 17px 0px;
    font-size: 16px;
  }
  .resetEmailText {
    padding: 23px 0px 17px 0px;
  }
  .emailContainer {
    text-align: left;
    width: 336px;
    margin: auto;
  }
  .emailInput {
    width: 336px;
  }
  .submitButton {
    width: 25%;
    display: inline-block;
  }
  .linkLeftMargin {
    margin-left: 40px !important;
  }
}

.loaderContainer {
  display: flex;
  height: 45px;
}

.loader {
  margin-left: 5px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .loaderContainer {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .loaderContainer {
    justify-content: center;
  }
}

.order-details-wrapper {
  width: 100%;
}

.order-inner-wrapper {
  /* background-color: #eff3f6; */
}

.order-top-content {
  background-color: white;
}

.order-top-row {
  padding: 15px 0;
}

.order-top-row .page-title {
  text-transform: uppercase;
  line-height: 45px;
  font-weight: bold;
}

.order-data {
  background-color: #ffffff;
  /* padding: 25px 0; */
  padding: 5% !important;
}

.order-container {
  padding: 0 !important;
}

.table-body td:last-child {
  text-align: left;
}

.MuiPaper-root .MuiCardHeader-root {
  background-color: #273142;
  color: white;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: bold;
}

.MuiCardHeader-root .MuiIconButton-root {
  color: white;
}

.order-summary td {
  border-bottom: none;
  padding: 2px 0;
}

.order-summary td:last-child {
  text-align: right;
}

.order-summary tr:last-child {
  /* border-top: 1px solid rgba(0, 0, 0, 0.12); */
}

.billing-wrapper .MuiCard-root {
  width: 100%;
  float: left;
  margin-right: 25px;
  margin-bottom: 15px;
}

.billing-shipping-container {
  padding: 15px 0;
}

.billing-wrapper .billing-title,
.order-summary-wrapper .summary-title {
  padding: 10px 0;
}

.order-summary-content td:first-child {
  font-weight: bold;
}

.upperCase {
  text-transform: uppercase;
}

.customer-info {
  margin: 10px 0;
}

.color-red {
  color: red;
}

.price {
  color: #237e9a !important;
  text-align: center;
  margin-top: 30px;
  /* text-align: left; */
}
.priceOrder {
  color: #237e9a !important;
  text-align: left;
}
@media (max-width: 768px) {
  .order-data {
    font-size: 80%;
  }

  .billing-wrapper .MuiCard-root {
    width: 100%;
  }
}

.search-header {
  position: relative;
  padding-bottom: 5px;
  top: -10px;
  margin-bottom: 4em;
  width: 100%;
}

.search-header h2 {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  line-height: 2.5;
  letter-spacing: 0.76px;
  color: #273142;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.search-field {
  position: absolute;
  display: inline-block;
  text-align: right;
  right: 0;
}
.MuiExpansionPanelDetails-root,
.order-details-panel {
  background: #ffffff !important;
}
.alignCenter {
  margin: auto;
  height: 200px;
}

/* ADDED from old orders */
.orders-inner-wrapper {
  /* background-color: #eff3f6; */
}

.orders-top-content {
  /* background-color: #fafbfc; */
}

.orders-wrapper {
  margin-bottom: 20px;
}

.ordersTopRow {
  padding: 15px 0;
}

.page-title {
  text-transform: uppercase;
  line-height: 45px;
  font-weight: bold;
}

.orders-data {
  background-color: #ffffff;
  padding: 0 15px;
}

.orders-container {
  padding: 0px;
}

.pagination-row {
  padding: 0 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
}

.view-details-btn {
  border: 1px solid rgba(151, 151, 151, 0.32) !important;
  border-radius: 4px !important;
  background-color: #273142 !important;
  color: #ffffff !important;
}

.table-head th {
  color: black;
  font-weight: bold;
}

.table-head .MuiTableCell-root {
  border-bottom: none;
}

.table-body td:first-child {
  text-transform: uppercase;
}

.table-body td:last-child {
  text-align: right;
}

.table-body .MuiTableCell-root {
  border-bottom: none;
}

.divider {
  margin: 4px;
}

.search-input {
  padding: 0 15px;
}

.table {
  display: table;
  width: 100%;
}

.table-head {
  display: table-header-group;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
}

.table-body {
  display: table-row-group;
}

.order-details-panel {
  border-top: 1px solid #fafbfc;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 0 10px #000;
}

.order-rows td {
  border-bottom: none;
}

.text-right {
  text-align: right;
}

.MuiExpansionPanelDetails-root {
  padding: 0px !important;
}
.orderSearch {
  display: flex !important;
}
.orderSearchPage {
  display: flex !important;
}
.selectOrder {
  /* width: 100%; */
  height: 40px;
  display: flex;
  padding: 0px 5px;
  align-items: center;
}
.selectDrop {
  /* width: 364px; */
  height: 40px;
  background: #fcfcfc;
  color: #a8a8a8 !important;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border: none;
  padding: 0px 5px;
}
.selectDropInvoice {
  background: #fcfcfc;
  color: #a8a8a8 !important;
  border: none;
  padding: 0px 5px;
}
.labelCss {
  color: #5b5e5f;
  padding-right: 5px;
  /* width: 100px; */
}

/* select {
  background: #fcfcfc;
  border: 1px solid #0066ff;
}
select option {
  background-color: transparent !important;
  border: 1px solid #e4e4e4 !important;
  color: #000 !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
} */
.noOrderDisplay {
  height: 100px;
  padding: 20px;
}
@media (max-width: 768px) {
  .orders-data {
    padding: 0;
  }

  .MuiExpansionPanelSummary-root {
    padding: 0px !important;
  }

  .view-details-btn-mob {
    display: flex;
    flex-direction: column-reverse;
  }

  .view-details-btn-mob .view-details-btn {
    font-size: 90%;
  }

  .mobile-grid {
    font-size: 75%;
  }

  .mobile-grid .headers {
    font-weight: bold;
  }

  .pagination-row {
    justify-content: center;
  }
}

.cell-width {
  width: 20%;
  /* padding: 0 !important; */
  /* padding: 12px 0px 12px 12px; */
  padding: 16px !important;
}

.zero-padding {
  padding: 0 !important;
}

.table-head {
  padding: 12px 0px 12px 12px;
}
.priceItem {
  color: #237e9a !important;
  text-align: left !important;
}

.order-rows td,
.order-rows th {
  padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
/* .orderHeadRow{  
  padding: 0;  
} */
.paddingLeft {
  padding-left: 5px;
}

.no-order-text {
  padding: 30px 0px 55px 0px;
}
@media (max-width: 991px) {
  .no-order-text {
    padding: 30px 20px 55px;
  }
}

.displayProp {
  display: flex;
  justify-content: space-between;
}

.image {
  width: 125px;
  height: 110px;
}

.rectangle {
  height: 125px;
  background: #f9fafb;
  border: 1px solid #dfe3e9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 7px;
  margin: 10px 0;
}

.marginDiv {
  margin-left: 60px;
  padding-right: 60px;
}

.items {
  margin-top: 12px;
  color: #237e9a;
  text-align: left;
}

.quickOrderPrice {
  margin-top: 30px;
  text-align: center;
}

.bottomBorder {
  width: 95%;
  border-bottom: 1px solid #ebedf8;
  padding-bottom: 15px;
}

#removeMargin {
  margin: 0;
}

#deleteDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dustBin:hover {
  cursor: pointer;
}

.noStock {
  color: #a01c1c;
  margin-top: 30px;
  /* margin-right: 38px; */
  font-size: medium;
}
.alertMsg {
  color: #a01c1c;
  margin-top: 5px;
  font-size: medium;
}

.nameSize {
  text-align: start;
}

.priceAlign {
  text-align: center;
}

.quickOrderStepper {
  /* display: flex;
  justify-content: center; */
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .image {
    width: 70px;
    height: 50px;
  }

  .marginDiv {
    margin-left: 6px;
    padding-right: 0px;
  }

  .rectangle {
    height: 194px;
  }
  /* .price {
    margin-top: 52px;
  } */

  .noStock {
    margin-top: 0;
    /* margin-right: 47px; */
    font-size: small;
  }
  #deleteDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  /* .price {
    margin-top: 50px;
  } */

  .marginDiv {
    margin-left: 68px;
    padding-right: 0px;
  }

  .rectangle {
    height: 115px;
  }

  .noStock {
    margin-right: 0;
    font-size: small;
  }
  #deleteDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .displayProp {
    margin-left: 10px;
  }
}

.showError {
  border: 2px solid #ff0000;
}

.right-align-search {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.orderProd {
  width: 100%;
  background-color: #ffffff;
  border: 5px black;
  height: 100%;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  margin-top: 20px;
  padding: 20px;
}

.orderSearch {
  width: 450px;
  margin: auto;
}

.orderProd ul {
  position: absolute;
  width: 450px;
}

.cards {
  height: auto;
  width: 100%;
}

#addToQuote {
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.32);
  background: #e41e26;
  color: #ffffff;
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
  margin: auto;
}

.margin-bottom {
  margin-bottom: 20px;
}

.element-width {
  width: 100%;
}

.element-width>div {
  width: 40%;
  display: inline-block;
}

.bottomButton {
  /* display: flex; */
  /* float: right; */
}

/* Added for upload file */

* {
  box-sizing: border-box;
  font-family: "Helvetica", sans-serif;
}

.container {
  text-align: center;
  padding: 15px;
  margin: 10px auto;
}

.csv-input {
  visibility: hidden;
}
.react-csv-input label {
  padding: 11px;
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-right: 10px;
  background: #e41e26;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
}
.container p {
  padding-top: 10px;
  color: #666;
}

.padLeft {
  padding-left: 10px
}

.padRight {
  padding-right: 10px
}

.button1 {
  padding: 10px;
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-right: 10px;
  background: #e41e26;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
}

.importFile {
  display: flex;
  padding-bottom: 10px;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .orderProd {
    padding: 6px;
  }

  .orderSearch {
    width: 100%;
  }

  .orderProd ul {
    width: 90%;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .orderProd {
    padding: 6px;
  }

  .orderSearch {
    width: 400px;
  }

  .orderProd ul {
    width: 400px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .element-width>div {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .element-width>div {
    width: 60%;
  }
}

.quoteContainer {
  padding: 10px;
  background: #ffffff;
}

.contactPadding {
  padding: 20px;
  margin: 20px !important;
}
.termsPadding {
  padding: 20px;
}

.about_img {
  width: 100%;
}
@media only screen and (min-width: 750px) {
  .about_img_2 {
    width: 50%
  }
}

@media only screen and (max-width: 749px) {
  .about_img_2 {
    width: 100%
  }
}
.customWidth {
    width: 9% !important;
    min-width: 10px !important;
    max-width: 10px;
}
.cell-width {
    padding: 15px !important;
}

.notes-data {
    padding: 10px 0px 0px 0px
}
.main-panel {
  margin: inherit;
}

.checkoutContainer {
  padding: 0 20px 40px;
  background-color: white;
}

.checkoutContainer .paddedContent {
  padding: 0 35px 15px 0;
}

.orderHeading {
  padding: 16px 16px 0;
}

.product-image {
  width: 118px;
  float: left;
  margin-right: 20px;
  border-radius: 5px;
  overflow: hidden;
}

.product-image img {
  width: auto;
  height: 80px;
  margin: 0 auto;
  display: block;
}

.product-name-checkout {
  font-weight: bold;
  font-weight: 16px;
  color: black;
}

.product-info {
  line-height: 2;
}

.thead th {
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}

.thead .MuiTableCell-root {
  border-bottom: none;
  padding-bottom: 0;
}

.po-container {
  /* margin: 10px 20px; */
  margin: 0px 20px;
}

#padding {
  padding: 14px;
}
.continueShopping > a {
  margin: 0 20px;
  padding-right: 40px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #134c5d;
  text-decoration: underline;
}
.showmodal {
  padding: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .checkoutContainer .paddedContent {
    padding: 0;
  }
}

