.text-align-left {
  text-align: left;
  margin: 0;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.1;
  letter-spacing: normal;
  color: #3f4143;
}

.logoContainer img {
  width: 140px;
}

.logoContainer.ppg img {
  width: auto;
  height: 40px;
}

.text-format {
  border-radius: 2px;
  border: solid 1px #dfe3e9;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 36px !important;
  background-color: #ffffff !important;
  box-sizing: border-box;
  width: 70%;
  padding: 2px;
}

.paper {
  margin-top: 25px;
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
}

/* .login-form {
    width: '100%';
    margin-top: 1px;
    text-align: 'center';

} */

.align-center {
  text-align: center;
}

.img-set {
  background-size: cover !important;
  background-color: #ffffff;
  height: 100vh !important;
  background-repeat: no-repeat !important;
}

/* .set-margin {
    padding-bottom: 33px;
    padding-top: 33px;
} */

.set-bottom {
  margin-top: 25px;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.text-style {
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #3f4243;
  padding-top: 40%;
}

/* #password,
#email {
    padding: 6px;
} */

html,
body {
  height: 100%;
  margin: 0;
}

.full-height {
  height: 100%;
  background: none;
}
#email,
#password {
  padding-left: 12px;
}
.mob-button {
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.32);
  background-color: rgba(216, 216, 216, 0);
  width: 25%;
  /* height: 40px; */
}
.mob-text {
  font-size: 14px !important;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
button {
  padding: 10px;
  font-size: 24px;
}

button:disabled {
  opacity: 0.5;
}
.circularLoader {
  /* width: 20%; */
  margin-left: 10px;
  /* display: inline-block; */
}
.forgotPasswordLink {
  color: #3f51b5;
}
.forgotPasswordLink:hover {
  text-decoration: underline;
}
