.customWidth {
    width: 9% !important;
    min-width: 10px !important;
    max-width: 10px;
}
.cell-width {
    padding: 15px !important;
}

.notes-data {
    padding: 10px 0px 0px 0px
}