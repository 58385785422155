.search-field-invoice {
  position: absolute;
  display: inline-block;
  text-align: right;
  right: 5em;
}
.mobileInvoiceLayout {
  padding: 1em;
  border-bottom: 1px solid #d5d5d5;
}
.customPadding {
  padding-bottom: 1em;
  margin: auto !important;
}

@media (min-width: 1200px) {
  .search-field-invoice {
    width: 40%;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .search-field-invoice {
    width: 50%;
    right: 1em !important;
  }
}

@media (max-width: 575px) {
  .search-field-invoice {
    width: 60%;
    margin-bottom: 10px;
    right: 1em !important;
  }
}
