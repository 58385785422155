.miniCart {
  position: absolute;
  right: 5em;
}

.miniCart-position {
  top: 5em;
}

.miniCart-layout {
  background: #ffffff;
  height: 400px;
  width: 300px;
  z-index: 9999999999;
  position: relative;
}

.headerMinicart {
  cursor: pointer;
  color: black;
}

/* added from  minicart layout */
.miniCart-position {
  position: absolute;
  right: 0;
  top: 20px;
  border-radius: 3px;
}

.miniCart-layout {
  background: #ffffff;
  height: 290px;
  width: 335px;
  z-index: 9999999999;
  position: relative;
  border-radius: 4px;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #edf1f5; */
  overflow-y: scroll;
}

.card-style {
  margin: 1em;
  padding: 1em;
}

.mini-cart-img {
  height: 65px;
  width: 50px;
  margin: 0 auto;
  border-radius: 4px;
  padding: 5px;
}

.product-text {
  /* font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.76px;
  color: #3f4143; */
  padding: 5px;
}

.productPriceCart {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #3f4143;
  padding-top: 5px;
}

.mini-cart-details {
  padding-left: 5px;
  padding-right: 5px;
}

.qty {
  float: left;
}

.sale-price {
  float: right;
}

.view-button {
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.32);
  background-color: #273142 !important;
  float: right;
  margin: 1em !important;
  padding: 1em !important;
  height: 38px;
  position: static;
}

.show-text {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
}

/* For minicart hover */
.miniCart-position {
  visibility: hidden;
  z-index: 1;
}

.miniCart:hover .miniCart-position {
  visibility: visible;
}

.set-cs {
  padding-top: 18px;
}

.button-wrap {
  z-index: 9999;
  background-color: white;
  position: absolute;
  float: right;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
