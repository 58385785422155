.product-details,
.product-feature {
  border-radius: 4px;
  background-color: #ffffff;
  margin-top: 35px;
}

.product-feature {
  margin-top: 20px;
}
