.contactPadding {
  padding: 20px;
  margin: 20px !important;
}
.termsPadding {
  padding: 20px;
}

.about_img {
  width: 100%;
}
@media only screen and (min-width: 750px) {
  .about_img_2 {
    width: 50%
  }
}

@media only screen and (max-width: 749px) {
  .about_img_2 {
    width: 100%
  }
}