.centerTextCat {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

.outerBorder {
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

.gallery img {
  width: 100%;
  height: auto;
  /* max-height: 187px; */
}

.gallery a[href*='Surface Adhesive'] img {
  width: auto;
  max-height: 187px;
}

.gallery a[href*='Powder'] img {
  width: auto;
  max-height: 187px;
}

.desc {
  padding: 15px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5b5e5f;
}

* {
  box-sizing: border-box;
}

.responsive {
  padding: 0 6px;
  float: left;
  width: 20%;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.catContainer {
  text-align: center;
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding-top: 15px;
  padding-bottom: 15px;
}

.containerCategory {
  width: 100%;
  margin-top: 33px;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
}

.viewText {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #5b5e5f !important;
  text-transform: capitalize !important;
}

.view {
  width: 100%;
  text-align: center;
}
