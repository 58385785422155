.productTitle {
  text-align: left !important;
  padding-left: 15px;
}

.order-cart-checkout {
  max-height: 778px;
  overflow: auto;
}

.order-cart-checkout .table .tbody td:first-child {
  width: 60%;
}

.order-cart-checkout .table .tbody td:last-child {
  padding: 0px 16px 0;
}

.order-cart-checkout .table .tbody .text-center {
  text-align: center;
  top: 0;
  padding: 0;
  margin: 0;
}

.order-cart-checkout .table .tbody .text-center input {
  text-align: center;
}

.order-cart-checkout .quantity-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.quantity-wrapper input {
  text-align: center;
}
/* added for checkout quantity */
.quantity-wrapper span {
  margin: auto;
}

.quantity-wrapper .Mui-disabled::before {
  border: none;
  border-bottom-style: none !important;
}

.item-qty-checkout {
  color: rgba(0, 0, 0, 0.38);
}

.order-cart-checkout .table .tbody .price-wrapper .edit-remove-btn {
  position: absolute;
  margin-top: 15px;
}

.order-cart-checkout .price:before {
  content: '$';
}

.text-uppercase {
  text-transform: uppercase;
}

.wrapper {
  display: grid;
  grid-template-columns: 5fr 1fr 1.2fr 1fr;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 16px;
  cursor: pointer;
}

.item-wrapper {
  height: 100%;
}

.item-header {
  font-weight: bold;
  color: #5b5e5f;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1.3125rem;
  /* remove to keep the text in center at checkout & cart page */
  /* padding: 14px 40px 0px 16px;  */
  padding-top: 14px;
  text-align: center;
}

.item-content {
  padding: 14px 14px 14px 16px;
  font-size: 0.875rem;
}

.item-content:last-child {
  padding: 0 16px;
}

.item-content.qty {
  /* padding-right: 0px; */
  padding: 0px;
  width: 100%;
}

.box-cell:nth-child(5) {
  grid-column-start: 4;
}

a {
  cursor: pointer;
}

@media (max-width: 768px) {
  .wrapper {
    grid-template-columns: repeat(3, 1fr);
  }

  .box-cell:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .box-cell:nth-child(5) {
    grid-column-start: 2;
    grid-column-end: 4;
    text-align: right;
  }

  .product-image {
    width: 34% !important;
  }
}
