
.miniFooter span {
    border-right: 0.01em solid #939696;
    padding-right: 0.5em;
    padding-left: 0.5em;
    cursor: pointer;
    color: #939696;
}

.miniFooter span:last-child { border-right: 0px; } 
  