.containerCustomer {
  margin-top: 33px;
  margin-bottom: 33px;
  position: relative;
  display: inline-block;
  width: 100%;
}
.centeredCustomer {
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #3f4143;
  text-align: left;
}
.imageResizeCustomer {
  width: 100%;
  height: 430px;
  border-radius: 2px;
}
.setWidthCustomer {
  width: 100%;
  height: 430px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.btn-click-customer {
  font-weight: normal;
  font-size: 16px;
  border-radius: 2px;
}
.centeredCustomer p {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
}
.textCenter {
  top: 40%;
  text-align: left;
  margin-top: 25%;
  padding-left: 5%;
}
