.image-box {
  text-align: center;
  /* padding: 30px 20px; */
  margin: 30px;
  /* background: #d5d5d5c2; */
}

.slick-prev:before,
.slick-next:before {
  color: #d5d5d5 !important;
}

.slick-slider .slick-initialized {
  width: 400px;
}
.product-details-box {
  padding: 30px 50px 30px 50px;
}

.product-name {
  font-size: 18px !important;
  font-weight: bold;
  line-height: 1.13;
  color: #3f4243;
}

/* .product-name {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.13;
  color: #3f4243;
} */

.price-box {
  margin-top: 30px;
}

.product-price {
  font-size: 20px;
  color: #3f4243;
  display: inline-block;
  padding: 0;
}

.product-status {
  font-size: 14px;
  color: #439e4e;
  display: inline-block;
  margin-left: 5px;
  padding: 0;
}

.outofstock-color {
  color: #ff0000;
}

.product-dimensions {
  clear: both;
  font-size: 14px;
  color: #5b5e5f;
  margin-top: 20px;
}

.product-dimensions p {
  padding: 0;
  margin: 0;
  line-height: 1.5;
}

.quantity-stepper-box {
  margin-top: 20px;
}

.quantity-stepper-box p {
  margin: 0;
  padding: 0;
}

.quantity-stepper {
  margin-top: 10px;
  width: 163px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #dfe3e9;
  background-color: #ffffff;
}

.product-quantity {
  color: #3f4243;
}

.button-minus,
.button-plus {
  width: 37px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #b0b1b2;
  background-color: #ffffff;
  cursor: pointer;
  padding-left: 0;
}

.quantity-field {
  width: 87px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  color: #5b5e5f;
  height: 40px;
  border: solid 1px #b0b1b2;
  padding-left: 0;
}

.add-to-quote {
  margin: 15px 0 20px 0;
  height: 40px;
  position: relative;
  display: flex;
}
/* Media Queries */
/* Smartphones (portrait and landscape) ----------- */
@media (max-width: 575px) {
  .image-box img {
    height: auto !important;
    /* max-height: 400px; */
    width: 100% !important;
    margin: 0 auto;
  }

  .image-box iframe {
    height: 100% !important;
    width: 100% !important;
  }

  .image-box video {
    height: 100% !important;
    width: 100% !important;
  }

  .product-details-box {
    padding: 20px;
  }

  .product-name {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.19;
    color: #3f4243;
  }

  .product-price {
    font-size: 16px;
    color: #3f4243;
    padding: 0;
    margin-bottom: 0;
  }

  .price-box {
    margin-top: 20px;
  }

  .product-status {
    font-size: 12px;
    color: #439e4e;
    display: inline-block;
    margin-left: 5px;
    padding: 0;
  }

  .outofstock-color {
    color: #ff0000;
  }

  .product-dimensions {
    font-size: 12px;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (min-width: 576px) and (max-width: 767px) {
  .image-box img {
    height: 250px !important;
    width: auto !important;
    margin: 0 auto;
  }
  .image-box iframe {
    height: 250px !important;
    width: 100% !important;
  }

  .image-box video {
    height: 250px !important;
    width: 100% !important;
  }
  .product-details-box {
    padding: 20px;
  }

  .product-name {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.19;
    color: #3f4243;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 768px) and (max-width: 1024px) {
  .image-box img {
    height: auto !important;
    /* max-height: 400px; */
    width: 100% !important;
    margin: 0 auto;
  }

  .image-box iframe {
    height: 400px !important;
    /* width: 350px !important; */
    width: 100%;
  }

  .image-box video {
    height: 400px !important;
    /* width: 350px !important; */
    width: 100%;
  }

  .product-details-box {
    padding: 30px 20px 20px 20px;
  }

  .product-name {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.19;
    color: #3f4243;
  }

  .product-price {
    font-size: 20px;
    color: #3f4243;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .image-box img {
    height: auto !important;
    /* max-height: 400px; */
    width: 100% !important;
    margin: 0 auto;
  }

  .image-box iframe {
    height: 400px !important;
    /* width: auto !important; */
    width: 100% !important;
  }

  .image-box video {
    height: 100% !important;
    width: 100% !important;
  }

  .product-details-box {
    padding: 30px 50px 30px 50px;
  }

  .product-name {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.19;
    color: #3f4243;
  }

  .product-price {
    font-size: 20px;
    color: #3f4243;
  }
}

.success {
  color: #02711c;
  font-weight: bold;
  font-size: 14px;
  font-style: italic;
}

.error {
  color: #de0909;
  font-weight: bold;
  font-size: 14px;
  font-style: italic;
}

.error-message {
  color: #de0909;
  font-weight: bold;
  font-size: 14px;
}

.green {
  color: green;
  font-weight: bold;
  font-size: 14px;
}
