.product-listing-container {
  padding: 20px;
}

.listing-left-panel {
  width: 100%;
}
.listing-right-panel {
  width: 100%;
}

.listing-facet {
  flex: 0 0 330px;
  max-width: 330px;
  padding: 0;
}

.listing-header {
  width: 100%;
  padding: 10px 0 15px 0;
  position: relative;
}

.filter {
  font-size: 16px;
  font-weight: bold;
  color: #3f4243;
}

.clear-all {
  font-size: 14px;
  color: #5b5e5f;
  text-decoration: underline;
  cursor: pointer;
}

.divider {
  width: 100%;
  height: 1px;
  background: #eff3f6;
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
  margin: 0;
}

.sort-by {
  font-size: 12px;
  color: #5b5e5f;
}

.sort-by-select {
  position: absolute;
  top: 10px;
  left: 60px;
}

.results-per-page-select {
  position: absolute;
  top: -9px;
  left: 110px;
}

.filter-type-container {
  max-width: 100%;
  padding: 8px;
  margin-top: 20px;
  max-height: 200px;
  overflow-y: auto;
}

.filter-type-label {
  font-size: 14px;
  color: #3f4243;
  margin: 10px 0 0 0px;
  line-height: 1;
}

.filter-checkbox {
  width: 17px;
  height: 17px;
}

.heirarchical-option {
  margin: 10px 0;
}
.filter-type-option {
  margin: 15px 0;
  max-height: 20px;
}

.filter-option-label {
  width: 70%;
  position: relative;
  top: 1px;
  font-family: SourceSansPro;
  font-size: 14px;
  color: #5b5e5f;
  margin-left: 10px;
  display: inline-block;
}

.filter-option-count {
  position: relative;
  top: 1px;
  display: inline-block;
  width: 10%;
  text-align: right;
  font-family: SourceSansPro;
  font-size: 16px;
  color: #5b5e5f;
}

.listing-panel-container {
  margin-top: 15px;
}

.card-image-list {
  width: 100%;
  height: 100%;
}

.MuiTreeItem-label {
  font-family: SourceSansPro !important;
  font-size: 14px !important;
  color: #5b5e5f !important;
}

.no-data-found {
  font-size: 18px;
  font-style: italic;
  margin-top: 10px;
  padding-left: 20px;
  color: #fd5531;
}

.pagination-grid-list {
  width: 50%;
  text-align: right;
  display: inline-block;
}

.pagination-grid-list .custom-pagination {
  vertical-align: super;
}

.activeView {
  width: 75px;
  border: 1px solid #a0a5a8;
  height: 25px;
  background: #a0a5a8;
  display: inline-flex;
  cursor: pointer;
}
.activeView-span {
  padding-left: 20px;
}

.inActive {
  width: 75px;
  border: 1px solid #a0a5a8;
  height: 25px;
  display: inline-flex;
  cursor: pointer;
}
/* Medium devices (landscape tablets, 768px and up) */
@media (max-width: 575px) {
  .filter-type-container {
    max-width: 100%;
    padding: 0;
    margin-top: -18px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* .results-per-page-select {
    left: 32%;
  } */
}

.filter-label {
  position: relative;
  right: 50px;
}

.filter-icon {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: solid 1px #edf1f5;
  position: absolute;
  right: 10px;
  top: 15px;
}

div#mobileFilter {
  width: 100%;
}

#mobileFilter .filter-type-container {
  max-height: fit-content;
  margin-top: 0px;
}

#mobileFilter span.MuiIconButton-label {
  font: icon;
}

#ancestor .MuiTypography-colorSecondary {
  color: #f50057;
}

#parent .MuiTypography-colorSecondary {
  color: #f50057;
}

#pageLayout .MuiGrid-spacing-xs-5 > .MuiGrid-item {
  padding: 0px;
}
