* {
  margin: 0;
  padding: 0;
}

.main-panel {
  padding: 30px 40px;
  position: relative;
}

.search-header {
  position: relative;
  padding-bottom: 5px;
  top: -10px;
  margin-bottom: 4em;
  width: 100%;
}

.search-header h2 {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  line-height: 2.5;
  letter-spacing: 0.76px;
  color: #273142;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.search-field {
  position: absolute;
  display: inline-block;
  text-align: right;
  right: 0;
}

@media (max-width: 575px) {
  .main-panel {
    padding: 15px 20px;
  }

  .search-header {
    /* top: -7px; */
    margin-bottom: 4em;
  }

  .search-header h2 {
    font-size: 14px;
  }

  .search-field {
    /* removed to show thw complete search on mobile */
    width: 100%;
    margin-bottom: 10px;
  }

  .search-field .makeStyles-root-174 {
    height: 35px !important;
  }
}

/* iPads (portrait and landscape) ----------- */
@media (min-width: 576px) and (max-width: 767px) {
  .main-panel {
    padding: 25px 30px;
  }
  .search-field {
    width: 60%;
  }
}

/* iPads (landscape) ----------- */
@media (min-width: 768px) and (max-width: 1199px) {
  .main-panel {
    padding: 30px 40px;
  }
  .search-field {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .main-panel {
    padding: 30px 40px;
  }
  .search-field {
    width: 40%;
  }
}
