.staticbanner {
  /* background-image: url('https://scx1.b-cdn.net/csz/news/800/2019/2-nature.jpg'); */
  /* background-image: url('./img/banner.jpg'); */
  background-color: #cccccc;
  height: 356px;
  background-size: cover;
  background-position-y: center;
  color: #ffffff;
}

/* .staticbanner.tigerdirect { */
/* background-position-x: center;
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-color: #066d68; */
/* } */

.staticbanner.ventec {
  /* background-image: url('./img/ventec-banner.jpg'); */
}

.staticbanner.ppg {
  /* background-image: url('./img/ppg-dashboard-image.jpg'); */
}

.dashboard-description > div {
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
}

.desktopCards {
  z-index: 999999;
  margin-top: -120px !important;
}
.bannerTexttablet {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.vAlign {
  transform: translateY(50%);
}
.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.Aligner-item--center {
  height: 33%;
}

.Aligner-item--top {
  height: 33%;
}

.Aligner-item--bottom {
  height: 30%;
  padding-top: 50px;
}
.cardNumbers {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #232323;
}
.cardMainText {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.2px;
  color: #232323;
}
.cardActionLink {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: #007bff;
}
.bannerWelcomeText {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  background: rgba(0, 0, 0, 0.7);
  padding: 2px 10px;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #f2f2f2;
}
.bannerSubText {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 2px;
  color: #f2f2f2;
}
.bannerSubTextValue {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #b2b2b2;
}
.announcementSectionTitle {
  height: 15%;
  padding-top: 15px;
  font-size: 20px;
}
.announcementSectionMainText {
  height: 70%;
  padding: 30px 40px;
  line-height: 1;
  font-size: 36px;
}
.announcementSectionAction {
  height: 10%;
  font-size: 20px;
  padding-top: 50px;
  padding-bottom: 10px;
}
.notificationTitle {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 2px;
  color: #f2f2f2;
}
.notificationSubText {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: #ffffff;
}
.notifyTitle {
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.29px;
  color: #ffffff;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.notifyMsg {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.46px;
  color: #ffffff;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}
.notifyDataHeading {
  font-size: 8px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  color: #666666;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.notifyDataHeadingValue {
  font-size: 10px;
  padding: 0 10px;
}
.notifyAction {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  color: #007bff;
}
.notificationTitleSection {
  /* height: 60px; */
  margin-bottom: 10px;
}
.notifySection {
  height: 40px;
  background-color: #111111;
  padding: 0px;
}
.notifyMainSection {
  /* height: 220px; */
  background-color: #232323;
}
.moreSection {
  height: 30px;
  padding-top: 10px;
  text-align: center;
}
@media only screen and (max-width: 800px) {
  .desktopCards {
    margin-top: 30px !important;
  }
  .staticbanner {
    height: 200px;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 960px) {
  /* STYLES GO HERE */
  /* added for dashboard banner  */
  .bannerWidth {
    max-width: 100% !important;
    flex-basis: 100% !important;
    /* flex-wrap: wrap; */
  }
}
@media only screen and (min-width: 1024) {
  /* STYLES GO HERE */
  /* added for dashboard banner  */
  .bannerWidth {
    max-width: 100% !important;
    flex-basis: 100% !important;
    /* flex-wrap: wrap; */
  }
}
@media only screen and (max-device-width: 1024px) and (min-device-width: 768px) {
  .latest-order-summary {
    display: block !important;
  }
}

.latest-orders {
  font-size: 16px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 2px;
  color: #232323;
  padding: 10px;
}

.common-style {
  border: solid 1px #cccccc;
  background-color: #ffffff;
}

.common-margin {
  margin: 20px;
}
.rowText td {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /* line-height: 40px; */
  color: #000000 !important;
  /* width: 20% !important; */
  border-bottom: none;
}

.quickOrder {
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 50px;
  color: #111111;
}

.headdata td,
.summary-data {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px;
  color: #232323;
}

.latest-order-summary td {
  padding-top: 0 !important;
  padding-bottom: 0;
}

.latest-order-summary th.MuiTableCell-root.MuiTableCell-head {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiTableCell-root .MuiTableCell-head .dashboard-order {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  color: #666666;
}

.outerTable th,
.summary-head {
  font-style: normal;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 30px;
  color: #666666;
}

.outerTable {
  background: #f2f2f2;
}

.inner-head th,
.head-text-db {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #666666;
  /* width: 20% !important; */
}

.inner-head {
  background: #e9e9e9;
}

.outerTable .MuiTableCell-root {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.outerTable th:nth-child(6) {
  color: green !important;
}

.outerTable td:nth-child(7) {
  width: 15%;
  text-align: center;
}

.outerTable td:nth-child(2),
td:nth-child(3) td:nth-child(4) {
  width: 30%;
}

.outerTable td:nth-child(7) {
  padding: 0 !important;
}
.outerTable th:nth-child(7) {
  color: #007bff !important;
  text-align: center;
}
.latest-order-summary {
  background: #fafafa;
  padding-left: 15px;
}
.add-scroll {
  overflow-x: scroll !important;
}
.text-center {
  text-align: center;
}
.add-padding {
  padding: 10px;
}
.display-flx {
  display: flex;
  text-align: center;
}
.add-border {
  border: solid 1px #cccccc;
}
.add-background {
  background: #f2f2f2;
}
.color-text {
  color: green !important;
}
.color-link {
  color: #007bff !important;
}
.orderHeader {
  display: flex;
  justify-content: center;
}
.display-flex {
  display: flex;
}
.reset-margin {
  margin-bottom: 0;
}
.icon-style {
  text-align: center;
  font-size: medium;
}
.inner-table thead {
  display: inline-block;
  width: 100%;
}
.inner-table tbody {
  overflow: auto;
  display: inline-block;
  width: 100%;
  height: 139px;
}
